const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/manage/corporates',
	name: 'MainCorporates',
	redirect: '/manage/corporates',
	component: Header,
	children: [
		{
			path: '/manage/corporates',
			name: 'Corporates',
			component: () => import('@/views/Users/Corporates'),
			meta: {
				auth: true,
				section: 'corporates',
				permissions: 'view',
			},
		},
		{
			path: '/manage/corporates/new-corporate',
			name: 'NewCorporate',
			component: () => import('@/views/Users/NewCorporate'),
			meta: {
				auth: true,
				section: 'corporates',
				permissions: 'create',
			},
		},
		{
			path: '/manage/corporates/:corporateId/update-corporate',
			name: 'UpdateCorporate',
			component: () => import('@/views/Users/UpdateCorporate'),
			meta: {
				auth: true,
				section: 'corporates',
				permissions: 'update',
			},
		},
		{
			path: '/manage/corporates/:corporateId/permissions',
			name: 'CorporatesPermissions',
			component: () => import('@/views/Permissions'),
			meta: {
				auth: true,
				section: 'corporates',
				permissions: 'update_permissions',
			},
		},
		{
			path: '/manage/corporates/:corporateId/change-password',
			name: 'UpdateCorporatePassword',
			component: () => import('@/views/Users/ChangePassword'),
			meta: {
				auth: true,
				section: 'corporates',
				permissions: 'update_password',
			},
		},
	],
};
