import store from '@/store/index';

const replicatedSite = {
	siteOn(replicatedId) {
		store.dispatch('replicated/siteOn', replicatedId);
	},
	siteOff() {
		store.dispatch('replicated/siteOff');
	},
	siteReplicatedSponsorOn(replicatedUser) {
		store.dispatch('replicated/siteReplicatedSponsorOn', replicatedUser);
	},
	siteReplicatedSponsorOff() {
		store.dispatch('replicated/siteReplicatedSponsorOff');
	},
	siteReplicatedPageOn(replicatedPage) {
		store.dispatch('replicated/siteReplicatedPageOn', replicatedPage);
	},
	siteReplicatedPageOff() {
		store.dispatch('replicated/siteReplicatedPageOff');
	},
	siteSetDiscountsCheck(checkForPromo) {
		store.dispatch('replicated/siteSetDiscountsCheck', checkForPromo);
	},
	siteSetDiscountCode(code) {
		store.dispatch('replicated/siteSetDiscountCode', code);
	},
	siteInit() {
		store.dispatch('replicated/siteInit');
	},
	replicatedId: () => store.getters['replicated/replicatedId'],
	replicatedSponsor: () => store.getters['replicated/replicatedSponsor'],
	replicatedSponsorGroup: () => store.getters['replicated/replicatedSponsorGroup'],
	replicatedCheckPromo: () => store.getters['replicated/replicatedCheckPromo'],
	replicatedPage: () => store.getters['replicated/replicatedPage'],
	replicatedHideStore: () => store.getters['replicated/replicatedHideStore'],
	replicatedDiscountCode: () => store.getters['replicated/replicatedDiscountCode'],
};

const Replicated = {
	install(Vue) {
		replicatedSite.siteInit();
		Vue.prototype.$replicated = replicatedSite;
	},
};

export default Replicated;
