const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/fill-autoship-info',
	name: 'AffiliateAutoshipInfo',
	redirect: { name: 'FillAffiliateAutoshipInfo' },
	component: Header,
	children: [
		{
			path: '/fill-autoship-info',
			name: 'FillAffiliateAutoshipInfo',
			component: () => import('@/views/BecomeAffiliate/FillAutoshipInfo'),
			meta: {
				auth: true,
				role: ['affiliate'],
			},
		},
	],
};
