const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/credits/add',
	name: 'Credits',
	redirect: '/credits/add',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/credits/add',
			name: 'CreditsAdd',
			component: () => import('@/views/Credits/Add'),
			meta: {
				auth: true,
				section: 'credits',
				permissions: 'add',
			},
		},
	],
};
