import { AGENCY_REPLICATED_SITE_PATHS } from '@/settings/Replicated';
import { formatPathName } from '@/util/AgencySite';

const path = AGENCY_REPLICATED_SITE_PATHS.google;
const Header = () => import('@/views/Layouts/Store');

export default {
	path: `/${path}`,
	name: formatPathName(path, 'MainStore'),
	redirect: `/${path}/store`,
	component: Header,
	children: [
		{
			path: `/${path}/store`,
			name: formatPathName(path, 'Store'),
			component: () => import('@/views/Agency/Store/'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: `/${path}/bran`,
			name: formatPathName(path, 'BranLandingPage'),
			component: () => import('@/views/Agency/LandingPages/Bran'),
		},
		{
			path: `/${path}/zlem`,
			name: formatPathName(path, 'ZlemLandingPage'),
			component: () => import('@/views/Agency/LandingPages/Zlem'),
		},
		{
			path: `/${path}/uuth`,
			name: formatPathName(path, 'UuthLandingPage'),
			component: () => import('@/views/Agency/LandingPages/Uuth'),
		},
		{
			path: `/${path}/plos`,
			name: formatPathName(path, 'PlosLandingPage'),
			component: () => import('@/views/Agency/LandingPages/Plos'),
		},
		{
			path: `/${path}/tuun`,
			name: formatPathName(path, 'TuunLandingPage'),
			component: () => import('@/views/Agency/LandingPages/Tuun'),
		},
		{
			path: `/${path}/byom`,
			name: formatPathName(path, 'ByomLandingPage'),
			component: () => import('@/views/Agency/LandingPages/Byom'),
		},
		{
			path: `/${path}/lite`,
			name: formatPathName(path, 'LiteLandingPage'),
			component: () => import('@/views/Agency/LandingPages/Lite'),
		},
	],
};
