export const SYSTEM_ALERT = [400, 500];
export const COMMON_ERRORS = [400, 429, 500];
export const BAD_REQUEST = [400];
export const AUTH = [401, 405];
export const FORBIDDEN = [403];
export const NOT_FOUND = [404];
export const CONFLICT = [409];
export const UNPROCESSABLE = [422];
export const LOCKED = [423];
export const THROTTLE = [429];
export const INTERNAL_SERVER_ERROR = [500];
export const ACCESS = [503];

export default {
	SYSTEM_ALERT,
	COMMON_ERRORS,
	BAD_REQUEST,
	AUTH,
	FORBIDDEN,
	NOT_FOUND,
	CONFLICT,
	UNPROCESSABLE,
	LOCKED,
	THROTTLE,
	INTERNAL_SERVER_ERROR,
	ACCESS,
};
