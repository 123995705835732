const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/manage/customers',
	name: 'MainCustomers',
	redirect: '/manage/customers',
	component: Header,
	children: [
		{
			path: '/manage/customers',
			name: 'Customers',
			component: () => import('@/views/Users/Customers'),
			meta: {
				auth: true,
				section: 'customers',
				permissions: 'view',
			},
		},
		{
			path: '/manage/customers/new-user',
			name: 'NewCustomer',
			component: () => import('@/views/Users/NewCustomer'),
			meta: {
				auth: true,
				section: 'customers',
				permissions: 'create',
			},
		},
		{
			path: '/manage/customers/:customerId/update-user',
			name: 'UpdateCustomer',
			component: () => import('@/views/Users/CustomersInformation'),
			meta: {
				auth: true,
				section: 'customers',
				permissions: 'update',
			},
		},
		{
			path: '/manage/customers/:customerId/change-password',
			name: 'UpdateCustomerPassword',
			component: () => import('@/views/Users/ChangePassword'),
			meta: {
				auth: true,
				section: 'customers',
				permissions: 'update_password',
			},
		},
		{
			path: '/manage/customers/:userId/add-address',
			name: 'AddCustomerAddress',
			component: () => import('@/views/Addresses/NewAddress'),
			meta: {
				auth: true,
				section: 'addresses',
				permissions: 'create',
			},
		},
		{
			path: '/manage/customers/:userId/upload-file',
			name: 'UploadCustomersFile',
			component: () => import('@/views/PersonalFiles/UploadFile'),
			meta: {
				auth: true,
				section: 'file',
				permissions: 'upload',
			},
		},
	],
};
