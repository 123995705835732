const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/autoship-information',
	name: 'MainAutoshipInformation',
	redirect: '/autoship-information',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/autoship-information',
			name: 'AutoshipInformation',
			component: () => import('@/views/AutoshipInformation'),
			meta: {
				auth: true,
				section: 'autoship_information',
				permissions: 'view',
			},
		},
	],
};
