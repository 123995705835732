import { distributor } from '@/settings/Roles';

const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/notifications',
	name: 'MainNotifications',
	redirect: '/notifications',
	component: Header,
	children: [
		{
			path: '/notifications',
			name: 'MobileNotifications',
			component: () => import('@/views/Notifications/MobileNotifications'),
			meta: {
				auth: true,
				role: [distributor],
			},
		},
		// {
		// 	path: '/notifications',
		// 	name: 'Notifications',
		// 	redirect: '/notifications/mobile',
		// 	component: () => import('@/views/Notifications'),
		// 	meta: {
		// 		auth: true,
		// 		role: distributor,
		// 	},
		// 	children: [
		// 		{
		// 			path: '/notifications/backoffice',
		// 			name: 'BackofficeNotifications',
		// 			component: () => import('@/views/Notifications/BackofficeNotifications'),
		// 			meta: {
		// 				auth: true,
		// 				role: distributor,
		// 			},
		// 		},
		// 		{
		// 			path: '/notifications/mobile',
		// 			name: 'MobileNotifications',
		// 			component: () => import('@/views/Notifications/MobileNotifications'),
		// 			meta: {
		// 				auth: true,
		// 				role: distributor,
		// 			},
		// 		},
		// 	],
		// },
	],
};
