import { windowSizes as sizes } from '@/settings/Screen';

export default {
	data() {
		return {
			windowWidth: null,
			sizes,
			windowWidthPx: null,
		};
	},
	created() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},
	computed: {
		maxWidthPx() {
			return this.sizes.find((size) => size.name === this.windowWidth).maxSize;
		},
	},
	methods: {
		handleResize() {
			const currentSize = this.sizes.find((size) => {
				this.windowWidthPx = window.innerWidth;
				if (this.windowWidthPx < size.maxSize) {
					return size;
				}
				return null;
			});
			this.windowWidth = currentSize ? currentSize.name : 'xl';
		},
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
};
