const MainHeader = () => import('@/views/Layouts/Main/Header');
const MembershipRenewalContainer = () => import('@/views/Layouts/Main/MembershipRenewalContainer');

export default {
	path: '/account-canceled',
	redirect: '/account-canceled',
	name: 'AccountCanceledContainer',
	component: MembershipRenewalContainer,
	children: [
		{
			path: '/account-canceled',
			name: 'AccountCanceledMain',
			redirect: '/account-canceled',
			component: MainHeader,
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/account-canceled',
					name: 'AccountCanceled',
					component: () => import('@/views/AccountCanceled'),
					meta: {
						auth: true,
						role: ['distributor'],
					},
				},
			],
		},
	],
};
