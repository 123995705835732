const MainHeader = () => import('@/views/Layouts/Main/Header');
const MembershipRenewalContainer = () => import('@/views/Layouts/Main/MembershipRenewalContainer');

export default {
	path: '/force-username-change',
	redirect: '/force-username-change',
	name: 'ForceUsernameChangeContainer',
	component: MembershipRenewalContainer,
	children: [
		{
			path: '/force-username-change',
			name: 'ForceUsernameChangeMain',
			redirect: '/force-username-change',
			component: MainHeader,
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/force-username-change',
					name: 'ForceUsernameChange',
					component: () => import('@/views/ForceUsernameChange'),
					meta: {
						auth: true,
					},
				},
			],
		},
	],
};
