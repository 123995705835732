import {
	VAR_NAME as varName,
	SPONSOR_GROUP_VAR_NAME as sponsorGroupVarName,
	SPONSOR_VAR_NAME as sponsorVarName,
	PAGE_VAR_NAME as pageVarName,
	REPLICATED_HIDE_STORE_VAR_NAME as replicatedHideStoreVarName,
	CHECK_FOR_PROMO_VAR_NAME as checkForPromoVarName,
	DISCOUNT_CODE as discountCodeVarName,
} from '@/settings/Replicated';

const storeIn = process.env.VUE_APP_AUTH_STORE;
const cookie = require('vue-cookie');

const replicated = {
	namespaced: true,
	state: {
		replicatedId: null,
		replicatedSponsor: null,
		replicatedSponsorGroup: null,
		replicatedHideStore: null,
		replicatedPage: null,
		replicatedCheckPromo: null,
		replicatedDiscountCode: null,
	},
	getters: {
		replicatedId(state) {
			return state.replicatedId;
		},
		replicatedSponsor(state) {
			return state.replicatedSponsor;
		},
		replicatedPage(state) {
			return state.replicatedPage;
		},
		replicatedSponsorGroup(state) {
			return state.replicatedSponsorGroup;
		},
		replicatedHideStore(state) {
			return state.replicatedHideStore;
		},
		replicatedCheckPromo(state) {
			return state.replicatedCheckPromo;
		},
		replicatedDiscountCode(state) {
			return state.replicatedDiscountCode;
		},
	},
	mutations: {
		setReplicatedId(state, replicatedId) {
			if (typeof replicatedId === 'string') {
				if (storeIn === 'COOKIE') {
					cookie.set(varName, replicatedId);
				}
				if (storeIn === 'LOCALSTORAGE') {
					localStorage.setItem(varName, replicatedId);
				}
				state.replicatedId = replicatedId;
			}
		},
		setReplicatedSponsor(state, replicatedUser) {
			if (typeof replicatedUser !== 'undefined' && replicatedUser !== null) {
				cookie.set('is_jns', replicatedUser.is_jns ? 1 : 0);
				const hideStore = replicatedUser.hide_store === true || replicatedUser.hide_store === 'true';
				if (storeIn === 'COOKIE') {
					cookie.set(sponsorVarName, replicatedUser.id);
					cookie.set(sponsorGroupVarName, replicatedUser.customer_group);
					cookie.set(replicatedHideStoreVarName, hideStore);
				}
				if (storeIn === 'LOCALSTORAGE') {
					localStorage.setItem(sponsorVarName, replicatedUser.id);
					localStorage.setItem(sponsorGroupVarName, replicatedUser.customer_group);
					localStorage.setItem(replicatedHideStoreVarName, hideStore);
				}
				state.replicatedSponsor = replicatedUser.id;
				state.replicatedSponsorGroup = replicatedUser.customer_group;
				state.replicatedHideStore = hideStore;
			}
		},
		setReplicatedPage(state, replicatedPage) {
			if (typeof replicatedPage === 'string') {
				if (storeIn === 'COOKIE') {
					cookie.set(pageVarName, replicatedPage);
				}
				if (storeIn === 'LOCALSTORAGE') {
					localStorage.setItem(pageVarName, replicatedPage);
				}
				state.replicatedPage = replicatedPage;
			}
		},
		setReplicatedCheckForPromo(state, checkForPromo) {
			const checkPromoVal = checkForPromo === true || checkForPromo === 'true';
			if (typeof checkPromoVal === 'boolean') {
				if (storeIn === 'COOKIE') {
					cookie.set(checkForPromoVarName, checkPromoVal);
				}
				if (storeIn === 'LOCALSTORAGE') {
					localStorage.setItem(checkForPromoVarName, checkPromoVal);
				}
				state.replicatedCheckPromo = checkPromoVal;
			}
		},
		setReplicatedDiscountCode(state, code) {
			if (typeof code === 'string') {
				if (storeIn === 'COOKIE') {
					cookie.set(discountCodeVarName, code);
				}
				if (storeIn === 'LOCALSTORAGE') {
					localStorage.setItem(discountCodeVarName, code);
				}
				state.replicatedDiscountCode = code;
			}
		},
		deleteReplicatedId(state) {
			state.replicatedId = null;
			if (storeIn === 'COOKIE') {
				cookie.delete(varName);
			}
			if (storeIn === 'LOCALSTORAGE') {
				localStorage.removeItem(varName);
			}
		},
		deleteReplicatedSponsor(state) {
			state.replicatedSponsor = null;
			if (storeIn === 'COOKIE') {
				cookie.delete(sponsorVarName);
			}
			if (storeIn === 'LOCALSTORAGE') {
				localStorage.removeItem(sponsorVarName);
			}
		},
		deleteReplicatedPage(state) {
			state.replicatedPage = null;
			if (storeIn === 'COOKIE') {
				cookie.delete(pageVarName);
			}
			if (storeIn === 'LOCALSTORAGE') {
				localStorage.removeItem(pageVarName);
			}
		},
		deleteReplicatedCheckForPromo(state) {
			state.replicatedCheckPromo = null;
			if (storeIn === 'COOKIE') {
				cookie.delete(checkForPromoVarName);
			}
			if (storeIn === 'LOCALSTORAGE') {
				localStorage.removeItem(checkForPromoVarName);
			}
		},
		deleteReplicatedDiscountCode(state) {
			state.replicatedDiscountCode = null;
			if (storeIn === 'COOKIE') {
				cookie.delete(discountCodeVarName);
			}
			if (storeIn === 'LOCALSTORAGE') {
				localStorage.removeItem(discountCodeVarName);
			}
		},
	},
	actions: {
		siteReplicatedSponsorOn({ commit }, replicatedUser) {
			commit('setReplicatedSponsor', replicatedUser);
		},
		siteReplicatedSponsorOff({ commit }) {
			commit('deleteReplicatedSponsor');
		},
		siteSetDiscountsCheck({ commit }, searchDiscount) {
			commit('setReplicatedCheckForPromo', searchDiscount);
		},
		siteSetDiscountCode({ commit }, discountCode) {
			if (discountCode === null) {
				commit('deleteReplicatedDiscountCode');
				return;
			}
			commit('setReplicatedDiscountCode', discountCode);
		},
		siteOn({ commit }, replicatedId) {
			commit('setReplicatedId', replicatedId);
		},
		siteOff({ commit }) {
			commit('deleteReplicatedId');
			commit('deleteReplicatedCheckForPromo');
			commit('deleteReplicatedDiscountCode');
		},
		siteReplicatedPageOn({ commit }, replicatedId) {
			commit('setReplicatedPage', replicatedId);
		},
		siteReplicatedPageOff({ commit }) {
			commit('deleteReplicatedPage');
			commit('deleteReplicatedCheckForPromo');
			commit('deleteReplicatedDiscountCode');
		},
		siteInit({ commit }) {
			let replicatedId = null;
			let replicatedSponsor = null;
			let replicatedSponsorGroup = null;
			let replicatedHideStore = null;
			let replicatedPage = null;
			let replicatedCheckPromo = null;
			let replicatedDiscountCode = null;
			const isJns = Boolean(Number(cookie.get('is_jns')));
			if (storeIn === 'COOKIE') {
				replicatedId = cookie.get(varName);
				replicatedSponsor = cookie.get(sponsorVarName);
				replicatedSponsorGroup = cookie.get(sponsorGroupVarName);
				replicatedPage = cookie.get(pageVarName);
				replicatedHideStore = cookie.get(replicatedHideStoreVarName);
				replicatedCheckPromo = cookie.get(checkForPromoVarName);
				replicatedDiscountCode = cookie.get(discountCodeVarName);
			}
			if (storeIn === 'LOCALSTORAGE') {
				replicatedId = localStorage.getItem(varName);
				replicatedSponsor = localStorage.getItem(sponsorVarName);
				replicatedSponsorGroup = localStorage.getItem(sponsorGroupVarName);
				replicatedPage = localStorage.getItem(pageVarName);
				replicatedHideStore = localStorage.getItem(replicatedHideStoreVarName);
				replicatedCheckPromo = localStorage.getItem(checkForPromoVarName);
				replicatedDiscountCode = localStorage.getItem(discountCodeVarName);
			}
			if (replicatedId) {
				commit('setReplicatedId', replicatedId);
			}
			if (replicatedSponsor) {
				const sponsor = {
					id: parseInt(replicatedSponsor, 10),
					customer_group: parseInt(replicatedSponsorGroup, 10),
					hide_store: replicatedHideStore,
					is_jns: isJns,
				};
				commit('setReplicatedSponsor', sponsor);
			}
			if (replicatedPage) {
				commit('setReplicatedPage', replicatedPage);
			}
			if (replicatedCheckPromo) {
				commit('setReplicatedCheckForPromo', replicatedCheckPromo);
			}
			if (replicatedDiscountCode) {
				commit('setReplicatedDiscountCode', replicatedDiscountCode);
			}
		},
	},
};

export default replicated;
