import { AGENCY_REPLICATED_SITE_PATHS } from '@/settings/Replicated';
import { formatPathName } from '@/util/AgencySite';

const path = AGENCY_REPLICATED_SITE_PATHS.google;
const Layout = () => import('@/views/Layouts/Purchase/CryptoPayment');

export default {
	path: `/${path}/crypto_payments/details/:wallet_address`,
	component: Layout,
	children: [
		{
			path: `/${path}/crypto_payments/details/:wallet_address`,
			name: formatPathName(path, 'CryptoPaymentsDetails'),
			component: () => import('@/views/CryptoPayments/Details'),
			meta: {
				auth: undefined,
			},
		},
	],
};
