const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/user-announcements',
	name: 'MainUserAnnouncements',
	redirect: '/user-announcements',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/user-announcements',
			name: 'UserAnnouncements',
			component: () => import('@/views/UserAnnouncements'),
			meta: {
				auth: true,
				section: 'user_announcements',
				permissions: 'view',
			},
		},
	],
};
