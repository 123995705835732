const Header = () => import('@/views/Layouts/Store');
export default {
	path: '/privacy-policy',
	component: Header,
	children: [
		{
			path: '/privacy-policy',
			name: 'PrivacyPolicy',
			component: () => import('@/views/OfficialDocument'),
			meta: {
				auth: undefined,
			},
		},
	],
};
