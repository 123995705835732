import { CASH_PAYMENT_STATUSES } from '@/settings/CashPayments';

const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/cash-payments',
	name: 'MainCashPayments',
	redirect: '/cash-payments',
	component: Header,
	children: [
		{
			path: '/cash-payments',
			name: 'CashPayments',
			redirect: '/cash-payments/pending',
			component: () => import('@/views/CashPayments'),
			meta: {
				auth: true,
				section: 'cash_payments',
				permissions: 'view',
			},
			children: [
				{
					path: '/cash-payments/pending',
					name: 'CashPaymentsPending',
					component: () => import('@/views/CashPayments/List'),
					meta: {
						auth: true,
						section: 'cash_payments',
						permissions: 'view',
					},
					props: {
						status: CASH_PAYMENT_STATUSES.pending,
					},
				},
				{
					path: '/cash-payments/approved',
					name: 'CashPaymentsApproved',
					component: () => import('@/views/CashPayments/List'),
					meta: {
						auth: true,
						section: 'cash_payments',
						permissions: 'view',
					},
					props: {
						status: CASH_PAYMENT_STATUSES.approved,
					},
				},
				// {
				// 	path: '/cash-payments/rejected',
				// 	name: 'CashPaymentsRejected',
				// 	component: () => import('@/views/CashPayments/List'),
				// 	meta: {
				// 		auth: true,
				// 		section: 'cash_payments',
				// 		permissions: 'view',
				// 	},
				// 	props: {
				// 		status: CASH_PAYMENT_STATUSES.rejected,
				// 	},
				// },
			],
		},
	],
};
