import { COUPON_STATUSES } from '@/settings/Coupons';

const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/coupons',
	name: 'MainCoupons',
	redirect: '/coupons',
	component: Header,
	children: [
		{
			path: '/coupons',
			name: 'Coupons',
			redirect: '/coupons/active',
			component: () => import('@/views/Coupons'),
			meta: {
				auth: true,
				section: 'coupons',
				permissions: 'view',
			},
			children: [
				{
					path: '/coupons/active',
					name: 'CouponsActive',
					component: () => import('@/views/Coupons/List'),
					meta: {
						auth: true,
						section: 'coupons',
						permissions: 'view',
					},
					props: {
						status: COUPON_STATUSES.active,
					},
				},
				{
					path: '/coupons/used',
					name: 'CouponsUsed',
					component: () => import('@/views/Coupons/List'),
					meta: {
						auth: true,
						section: 'coupons',
						permissions: 'view',
					},
					props: {
						status: COUPON_STATUSES.used,
					},
				},
				{
					path: '/coupons/expired',
					name: 'CouponsExpired',
					component: () => import('@/views/Coupons/List'),
					meta: {
						auth: true,
						section: 'coupons',
						permissions: 'view',
					},
					props: {
						status: COUPON_STATUSES.expired,
					},
				},
			],
		},
		{
			path: '/coupons/generate',
			name: 'CouponsGenerate',
			component: () => import('@/views/Coupons/Generate'),
			meta: {
				auth: true,
				section: 'coupons',
				permissions: 'create',
			},
		},
	],
};
