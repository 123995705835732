const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/banners',
	name: 'MainBanners',
	redirect: '/banners',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/banners',
			name: 'Banners',
			component: () => import('@/views/Banners'),
			meta: {
				auth: true,
				section: 'banners',
				permissions: 'view',
			},
		},
		{
			path: '/banners/new',
			name: 'NewBanner',
			component: () => import('@/views/Banners/NewBanner'),
			meta: {
				auth: true,
				section: 'banners',
				permissions: 'create',
			},
		},
		{
			path: '/banners/:bannerId/update',
			name: 'UpdateBanner',
			component: () => import('@/views/Banners/UpdateBanner'),
			meta: {
				auth: true,
				section: 'banners',
				permissions: 'update',
			},
		},
	],
};
