const defaultSystemLang = process.env.VUE_APP_LANG;
/* eslint-disable no-param-reassign */
class MultiLanguage {
	/* constructor, setter languages object */
	init(_languages, _vue) {
		this.languages = _languages;
		this.vue = _vue;

		let userLang = defaultSystemLang;

		/* if we're in a browser */
		let isBrowser = false;
		try {
			isBrowser = typeof navigator !== 'undefined';
		} catch (e) {
			isBrowser = false;
		}

		if (isBrowser) {
			userLang = navigator.language || navigator.userLanguage;
			userLang = this.matchLanguage(userLang) || this.matchLanguage(userLang.substr(0, 2));
		}

		if (!userLang) { [userLang] = Object.keys(this.languages); }

		this.userLang = userLang;

		if (localStorage.getItem('vue-lang') !== null) { this.userLang = localStorage.getItem('vue-lang'); }

		window.localStorage.setItem('vue-lang', this.userLang);
	}

	getLanguage = (findLang, binding) => {
		if (!findLang) {
			return '';
		}

		let find = findLang;
		Object.keys(binding.modifiers).forEach((path) => {
			const f = find[path.trim()];
			if (f) {
				find = f;
			}
		});
		return typeof find === 'string' ? find : '';
	}

	relaunchByDirective = (el, binding, vnode) => {
		const hasParams = typeof binding.value !== 'undefined';
		let current;
		let params = [];

		if (hasParams) {
			params = binding.value;

			if (typeof params !== 'object') { params = [params]; }
		}

		if (vnode.context.$options.messages) {
			current = this.getLanguage(vnode.context.$options.messages[vnode.context.language], binding);
		}

		if (!current) {
			current = this.getLanguage(this.languages[vnode.context.language], binding);
		}

		if (!current) {
			const val = el.innerHTML.trim();
			if (val !== '') {
				if (!vnode.context.$options.messages) { vnode.context.$options.messages = {}; }

				let optionPath = vnode.context.$options.messages[vnode.context.language];

				if (!optionPath) {
					optionPath = {};
					vnode.context.$options.messages[vnode.context.language] = {};
				}

				const modifiers = Object.keys(binding.modifiers);

				modifiers.forEach((path, idx) => {
					if ((idx + 1) === modifiers.length) {
						optionPath[path] = {};
						optionPath = optionPath[path];
					} else { optionPath[path] = val; }
				});

				current = val;
			}
		}

		if (current && hasParams) {
			Object.keys(params).forEach((path) => {
				current = current.replace(`{${path}}`, params[path]);
			});
		}

		el.innerHTML = current;
	}

	search = (current, path, params) => {
		if (path === null) {
			return '';
		}
		if (path.indexOf('.') !== -1) {
			path = path.split('.');
		} else {
			path = [path];
		}

		if (typeof params !== 'object') {
			params = [params];
		}

		let find = current;
		const [key] = path;
		try {
			find = find[key.trim()];
		} catch (error) {
			find = undefined;
		}

		if (typeof find !== 'undefined') {
			Object.keys(params).forEach((replace) => {
				find = find.replace(new RegExp(`{${replace}}`, 'g'), params[replace]);
			});
		}

		return find;
	}

	matchLanguage(lang) {
		if (!lang) {
			return '';
		}
		let match = '';
		Object.keys(this.languages).forEach((path) => {
			path = path.toLowerCase();
			if (lang.toLowerCase() === path) {
				match = path;
			}
		});

		return match;
	}

	mixMessages = (messages) => {
		let langs = {};
		messages.forEach((message) => {
			langs = { ...langs, ...message };
		});
		const newMessage = {};
		Object.keys(langs).forEach((lang) => {
			messages.forEach((message) => {
				if (message[lang]) {
					newMessage[lang] = { ...newMessage[lang], ...message[lang] };
				}
			});
		});
		return newMessage;
	}
}

const multi = new MultiLanguage();

/* Register in VueJS 2, receive path from language and default language */
MultiLanguage.install = (Vue, languages) => {
	const bus = new Vue();
	multi.init(languages, Vue);

	Vue.mixin({
		data() {
			return { language: window.localStorage.getItem('vue-lang') };
		},
		watch: {
			language(value) {
				multi.defaultLanguage = value;
				// TO DO Change the place where the language is stored
				window.localStorage.setItem('vue-lang', value);
				bus.$emit('lang-changed', multi.defaultLanguage);
				this.$forceUpdate();
			},
		},
		beforeMount() {
			if (Array.isArray(this.$options.messages)) {
				this.$options.messages = multi.mixMessages(this.$options.messages);
			}
		},
		mounted() {
			bus.$on('lang-changed', (language) => {
				this.language = language;
				return language;
			});
		},
		methods: {
			translate(path, params = {}) {
				let result = '';
				if (typeof path === 'undefined') {
					return result;
				}

				if (this.$options.messages && this.$options.messages[this.language]) {
					result = multi.search(this.$options.messages[this.language], path, params);
				}

				if (!result) {
					result = multi.search(multi.languages[this.language], path, params);
				}

				if (!result && this.$options.messages && this.$options.messages[defaultSystemLang]) {
					result = multi.search(this.$options.messages[defaultSystemLang], path, params);
					if (result) {
						console.warn(`The key ${path} is not in the "${this.language}" language currently`);
					}
				}

				if (!result) {
					result = multi.search(multi.languages[defaultSystemLang], path, params);
					if (result) {
						console.warn(`The key ${path} is not in the "${this.language}" language currently`);
					}
				}

				if (!result) {
					const response = path;
					if (process.env.NODE_ENV !== 'production') {
						console.warn(`The key ${path} is not currently in lang files`);
					}
					return response;
				}

				return result;
			},
		},
	});

	/* create directive, change content with modifications in components */
	Vue.directive('lang', {
		bind(el, binding, vnode) {
			multi.relaunchByDirective(el, binding, vnode);
		},
		componentUpdated(el, binding, vnode) {
			multi.relaunchByDirective(el, binding, vnode);
		},
	});
};

/* export my class */
export default MultiLanguage;
