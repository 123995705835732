/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import { api } from '@/config/axios/index';
import { FETCH_USER } from '@/config/endpoint';
import { admin } from '@/settings/Roles';

const authVarName = process.env.VUE_APP_AUTH_VAR_NAME;
const authStore = process.env.VUE_APP_AUTH_STORE;

const cookie = require('vue-cookie');

const login = {
	namespaced: true,
	state: {
		user: {},
		authToken: null,
		errors: [],
		loading: true,
		isLogged: false,
		documentLoaded: false,
	},
	getters: {
		ready(state) {
			return !state.loading && state.documentLoaded;
		},
		authenticated(state) {
			return state.isLogged;
		},
		authToken(state) {
			return state.authToken;
		},
		user(state) {
			return state.user;
		},
	},
	mutations: {
		setToken(state, authToken) {
			if (typeof authToken === 'string') {
				if (authStore === 'COOKIE') {
					cookie.set(authVarName, authToken);
				}
				if (authStore === 'LOCALSTORAGE') {
					localStorage.setItem(authVarName, authToken);
				}
				state.authToken = authToken;
			}
		},
		deleteToken(state) {
			state.authToken = null;
			if (authStore === 'COOKIE') {
				cookie.delete(authVarName);
			}
			if (authStore === 'LOCALSTORAGE') {
				localStorage.removeItem(authVarName);
			}
		},
		resetUser(state) {
			state.user = {};
		},
		endSession(state) {
			state.user = {};
			state.authToken = null;
			state.errors = [];
			state.loading = false;
			state.isLogged = false;
		},
		pushError(state, error) {
			state.push(error);
		},
		startSession(state) {
			state.loading = false;
			state.isLogged = true;
		},
		setUser(state, user) {
			state.user = user;
			state.isLogged = true;
		},
		documentLoaded(state) {
			state.documentLoaded = true;
		},
	},
	actions: {
		init({ dispatch, commit }) {
			window.onload = () => {
				commit('documentLoaded');
			};
			return dispatch('checkForSession')
				.then(() => dispatch('getPermissions')
					.then((response) => {
						const { type } = response.data.response;
						if (process.env.VUE_APP_ENV !== 'local' && process.env.VUE_APP_CC_PAYMENT_PROCESSOR === 'LPT' && !admin.includes(type)) {
							const userId = response.data.response.id;
							console.log('Login store init : ', userId);
							// eslint-disable-next-line
							_sift.push(['_setUserId', userId]);
						}
						return response;
					})
					.catch((error) => Promise.reject(error)))
				.catch((error) => Promise.reject(error));
		},
		logout({ commit }) {
			commit('deleteToken');
			commit('endSession');
			commit('resetUser');
			if (process.env.VUE_APP_ENV !== 'local' && process.env.VUE_APP_CC_PAYMENT_PROCESSOR === 'LPT') {
				console.log('Set user id blank in logout');
				// eslint-disable-next-line
				_sift.push(['_setUserId', '']);
			}
		},
		login({ commit }, token) {
			commit('setToken', token);
		},
		getPermissions({ commit }) {
			return api[FETCH_USER.method](FETCH_USER.endpoint()).then((response) => {
				commit('setUser', response.data.response);
				return response;
			}).catch((error) => {
				if (error.response.status === 401) {
					commit('deleteToken');
					commit('endSession');
				}
				return Promise.reject(error);
			});
		},
		checkForSession({ commit, getters }) {
			let { authToken } = getters;
			if (authStore === 'COOKIE' && authToken === null) {
				authToken = cookie.get(authVarName);
			}
			if (authToken === null) {
				commit('endSession');
				return Promise.reject(new Error('no_token_found'));
			}
			if (typeof authToken === 'string' && authToken !== '') {
				commit('setToken', authToken);
			}
			return true;
		},
		startSession({ commit }) {
			commit('startSession');
		},
		setUser({ commit }, info) {
			commit('setUser', info);
		},
	},
};

export default login;
