const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/media-library',
	name: 'MainMediaLibrary',
	redirect: '/media-library',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/media-library',
			name: 'MediaLibrary',
			component: () => import('@/views/MediaLibrary'),
			meta: {
				auth: true,
				section: 'media_library',
				permissions: 'view',
			},
		},
		{
			path: '/media-library/new',
			name: 'NewMediaItem',
			component: () => import('@/views/MediaLibrary/NewMediaItem'),
			meta: {
				auth: true,
				section: 'media_library',
				permissions: 'create',
			},
		},
		{
			path: '/media-library/update/:mediaId',
			name: 'EditMediaItem',
			component: () => import('@/views/MediaLibrary/EditMediaItem'),
			meta: {
				auth: true,
				section: 'media_library',
				permissions: 'update',
			},
		},
	],
};
