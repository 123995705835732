const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/order-verifications',
	name: 'MainOrderVerifications',
	redirect: '/order-verifications',
	component: Header,
	children: [
		{
			path: '/order-verifications',
			name: 'OrderVerifications',
			redirect: '/order-verifications/billing',
			component: () => import('@/views/OrderVerifications'),
			children: [
				{
					path: '/order-verifications/billing',
					name: 'OrderVerificationsPending',
					component: () => import('@/views/OrderVerifications/Billing.vue'),
					meta: {
						auth: true,
						section: 'orders',
						permissions: 'verify',
					},
				},
				{
					path: '/order-verifications/shipping',
					name: 'OrderVerificationsShipping',
					component: () => import('@/views/OrderVerifications/Shipping.vue'),
					meta: {
						auth: true,
						section: 'orders',
						permissions: 'verify',
					},
				},
			],
		},
	],
};
