const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/sms',
	name: 'MainSms',
	redirect: '/sms',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/sms',
			name: 'SmsGrid',
			component: () => import('@/views/Sms'),
			meta: {
				auth: true,
				section: 'sms_notification',
				permissions: 'view',
			},
		},
		{
			path: '/sms/send',
			name: 'SendSms',
			component: () => import('@/views/Sms/Send'),
			meta: {
				auth: true,
				section: 'sms_notification',
				permissions: 'create',
			},
		},
	],
};
