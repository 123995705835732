const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/product-reviews',
	name: 'MainProductReviews',
	redirect: '/product-reviews/reviewed',
	component: Header,
	children: [
		{
			path: '/product-reviews/reviewed',
			name: 'ReviewedProducts',
			component: () => import('@/views/ProductReviews/ReviewedProducts.vue'),
			meta: {
				auth: true,
				section: 'product_reviews',
				permissions: 'view',
			},
		},
		{
			path: '/product-reviews/:reviewId/reports',
			name: 'ReviewReports',
			component: () => import('@/views/ProductReviews/ReviewReports.vue'),
			meta: {
				auth: true,
				section: 'product_reviews',
				permissions: 'hide',
			},
		},
	],
};
