const active = 'active';
const used = 'used';
const expired = 'expired';

export const COUPON_STATUSES = {
	active,
	used,
	expired,
};

export const ALLOW_PERMANENT_COUPONS_IN_COUNTRIES = ['JP'];
export const COUPONS_MIN_PERCENTAGE = 1;
export const COUPONS_MAX_PERCENTAGE = 25;

export default {};
