const pending = 'pending';
const approved = 'processing,complete,closed';
const rejected = 'rejected';

export const CASH_PAYMENT_STATUSES = {
	pending,
	approved,
	rejected,
};

export default {};
