import { AGENCY_REPLICATED_SITE_PATHS } from '@/settings/Replicated';
import { formatPathName } from '@/util/AgencySite';

const path = AGENCY_REPLICATED_SITE_PATHS.meta;
const Header = () => import('@/views/Layouts/Store');

export default {
	path: `/${path}/product-details`,
	name: formatPathName(path, 'OtherProducts'),
	redirect: `/${path}/product-details`,
	component: Header,
	children: [
		{
			path: `/${path}/product-details/:productName?`,
			name: formatPathName(path, 'ProductDetails'),
			component: () => import('@/views/Agency/ProductDetails'),
			meta: {
				auth: undefined,
			},
		},
	],
};
