const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/announcements',
	name: 'MainAnnouncements',
	redirect: '/announcements',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/announcements',
			name: 'Announcements',
			component: () => import('@/views/Announcements'),
			meta: {
				auth: true,
				section: 'announcements',
				permissions: 'view',
			},
		},
		{
			path: '/announcements/new',
			name: 'NewAnnouncement',
			component: () => import('@/views/Announcements/NewAnnouncement'),
			meta: {
				auth: true,
				section: 'announcements',
				permissions: 'create',
			},
		},
		{
			path: '/announcements/:announcementId/update',
			name: 'UpdateAnnouncement',
			component: () => import('@/views/Announcements/UpdateAnnouncement'),
			meta: {
				auth: true,
				section: 'announcements',
				permissions: 'update',
			},
		},
	],
};
