import moment from 'moment';
import { LOGOUT_TIME_MILLISECONDS } from '@/settings/LogoutTime';

export default {
	data() {
		return {
			timer: null,
			logoutTime: LOGOUT_TIME_MILLISECONDS,
		};
	},
	created() {
		clearTimeout(this.timer);
		this.assignEvents();
	},
	beforeDestroy() {
		clearTimeout(this.timer);
		this.removeEvents();
	},
	methods: {
		autoLogout() {
			if (this.$user.auth() && !this.isSessionAlive(localStorage.getItem('sessionRefreshTime'))) {
				this.$user.logout().then(() => this.$router.push({ name: 'Login' }));
			}
		},

		isSessionAlive(sessionRefreshTime) {
			if (!sessionRefreshTime) localStorage.setItem('sessionRefreshTime', moment());
			const diff = moment().diff(sessionRefreshTime);
			const duration = moment.duration(diff).asMilliseconds();
			return this.logoutTime > duration;
		},

		resetTimer() {
			localStorage.setItem('sessionRefreshTime', moment());
			clearTimeout(this.timer);
			this.timer = setTimeout(() => { this.autoLogout(); }, this.logoutTime);
		},

		assignEvents() {
			window.addEventListener('mousemove', this.resetTimer);
			window.addEventListener('mousedown', this.resetTimer);
			window.addEventListener('click', this.resetTimer);
			window.addEventListener('scroll', this.resetTimer);
			window.addEventListener('keypress', this.resetTimer);
		},

		removeEvents() {
			window.removeEventListener('mousemove', this.resetTimer);
			window.removeEventListener('mousedown', this.resetTimer);
			window.removeEventListener('click', this.resetTimer);
			window.removeEventListener('scroll', this.resetTimer);
			window.removeEventListener('keypress', this.resetTimer);
		},
	},
};
