/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { BECOME_DISTRIBUTOR_STEPS } from '@/settings/Wizard';
import { customerRoles } from '@/settings/Roles';

const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/become-distributor',
	name: 'BecomeDistributor',
	redirect: { name: Object.keys(BECOME_DISTRIBUTOR_STEPS)[0] },
	component: Header,
	children: [
		{
			path: '/become-distributor/pack',
			name: 'BecomeDistributorPack',
			component: () => import('@/views/BecomeDistributor/Pack'),
			meta: {
				auth: true,
				role: customerRoles,
			},
		},
		{
			path: '/become-distributor/account-and-shipping',
			name: 'BecomeDistributorAccountAndShipping',
			component: () => import('@/views/BecomeDistributor/AccountAndShipping'),
			meta: {
				auth: true,
				role: customerRoles,
			},
		},
		{
			path: '/become-distributor/payment-method',
			name: 'BecomeDistributorPaymentMethod',
			component: () => import('@/views/BecomeDistributor/PaymentMethod'),
			meta: {
				auth: true,
				role: customerRoles,
			},
		},
		{
			path: '/become-distributor/confirmation',
			name: 'BecomeDistributorConfirmation',
			component: () => import('@/views/BecomeDistributor/Confirmation'),
			meta: {
				auth: true,
				role: customerRoles,
			},
		},
		{
			path: '/become-distributor/success',
			name: 'BecomeDistributorSuccess',
			component: () => import('@/views/BecomeDistributor/Success'),
			meta: {
				auth: true,
				role: customerRoles,
			},
			props: (route) => ({
				paymentMethodName: route.query.payment_method ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
			}),
		},
	],
};
