export const TOAST = {
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
};

// White list for messsages in views
export const WHITELIST_TYPES = ['success', 'info', 'error'];
export const WHITELIST_MODULES = ['default', 'email-change'];
export const WHITELIST_CUSTOM_TYPES = {
	'email-change': ['invalid'],
};

export default {};
