const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/settings',
	name: 'MainSettings',
	redirect: '/settings',
	component: Header,
	children: [
		{
			path: '/settings',
			name: 'Settings',
			redirect: '/settings/general',
			component: () => import('@/views/Settings/Settings'),
			children: [
				{
					path: '/settings/general',
					name: 'SettingsGeneral',
					component: () => import('@/views/Settings/General'),
					meta: {
						auth: true,
						section: 'settings',
						permissions: 'view',
					},
				},
				{
					path: '/settings/exchange-rates',
					name: 'ExchangeRates',
					component: () => import('@/views/Settings/ExchangeRates'),
					meta: {
						auth: true,
						section: 'exchange_rates',
						permissions: 'view',
					},
				},
			],
		},
	],
};
