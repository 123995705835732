// All routes that should be tracked by vue-gtm
// Values should be the route names
// Empty means all routes
export const TRACKED_ROUTES = [];

// All countries that should be tracked by vue-gtm
export const TRACKED_COUNTRIES = []; // Empty means all countries

export const EVENTS = {
	// Products
	productQtyUpdated: {
		event: 'product_qty_updated',
		category: 'Product',
		action: 'product_qty_updated',
		label: 'Product Quantity updated',
		nonInteraction: false,
	},

	// Checkout
	checkoutPersonalInfoEdit: {
		event: 'checkout_personal_information_edit',
		category: 'Checkout',
		action: 'edit',
		label: 'Personal Information Step',
		nonInteraction: false,
	},
	checkoutPhoneVerificationStart: {
		event: 'checkout_phone_verification_start',
		category: 'Checkout',
		action: 'start',
		label: 'Phone Verification Step',
		nonInteraction: false,
	},
	checkoutShippingInfoEdit: {
		event: 'checkout_shipping_information_edit',
		category: 'Checkout',
		action: 'edit',
		label: 'Shipping Information Step',
		nonInteraction: false,
	},
	checkoutPaymentInfoEdit: {
		event: 'checkout_payment_information_edit',
		category: 'Checkout',
		action: 'edit',
		label: 'Payment Information Step',
		nonInteraction: false,
	},
	checkoutConfirmationStart: {
		event: 'checkout_confirmation_start',
		category: 'Checkout',
		action: 'start',
		label: 'Confirmation Step',
		nonInteraction: false,
	},
	checkoutConfirmationEnd: {
		event: 'checkout_confirmation_end',
		category: 'Checkout',
		action: 'end',
		label: 'Confirmation Step',
		nonInteraction: false,
	},
};

export default {};
