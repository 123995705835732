/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import Vue from 'vue';
import Vuex from 'vuex';
import login from './Login';
import replicated from './Replicated';
import position from './Position';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		login,
		replicated,
		position,
	},
});
