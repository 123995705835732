const Header = () => import('@/views/Layouts/Main/Header');

export default {
	path: '/warehouse-orders',
	name: 'MainWarehouseOrders',
	component: Header,
	redirect: '/warehouse-orders',
	children: [
		{
			path: '/warehouse-orders',
			name: 'WarehouseOrders',
			component: () => import('@/views/WarehouseOrders'),
			redirect: '/warehouse-orders/pending',
			meta: {
				auth: true,
				section: 'inventory',
				permissions: 'view',
			},
			children: [
				{
					path: '/warehouse-orders/pending',
					name: 'PendingWarehouseOrders',
					component: () => import('@/views/WarehouseOrders/PendingWarehouseOrders'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/warehouse-orders/ready-for-shipment',
					name: 'ReadyForShipmentWarehouseOrders',
					component: () => import('@/views/WarehouseOrders/ReadyForShipmentWarehouseOrders.vue'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/warehouse-orders/exceptions',
					name: 'WarehouseOrdersExceptions',
					component: () => import('@/views/WarehouseOrders/WarehouseOrdersExceptions.vue'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
			],
		},
	],
};
