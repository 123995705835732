const Header = () => import('@/views/PhysicalStore/Layouts/Store');
export default {
	path: '/pickup/store',
	name: 'PSMainStore',
	redirect: '/pickup/store',
	component: Header,
	children: [
		{
			path: '/pickup/store',
			name: 'PSStore',
			component: () => import('@/views/PhysicalStore/Store/index'),
			meta: {
				auth: true,
				section: 'pickup_store',
				permissions: 'view',
			},
		},
		{
			path: '/pickup/upgrade',
			name: 'PSUpgrade',
			redirect: {
				name: 'PSStore',
				query: {
					option: 'our-products',
				},
			},
			meta: {
				auth: true,
				section: 'pickup_store',
				permissions: 'view',
			},
		},
	],
};
