import Vue from 'vue';
import Vuex from 'vuex';
import { REGISTER_STEPS as flowSteps } from '@/settings/Wizard';

const steps = [];
Object.keys(flowSteps).forEach((stepName) => {
	if (flowSteps[stepName].innerSteps) {
		steps.push(...flowSteps[stepName].innerSteps);
	}
	steps.push({ [stepName]: flowSteps[stepName] });
});

const options = {};
steps.forEach((step) => {
	options[Object.keys(step)[0]] = {};
});

Vue.use(Vuex);

const Store = new Vuex.Store({
	strict: process.env.VUE_APP_ENV !== 'production',
	state: {
		currentStep: 0,
		completedSteps: [],
		cartId: '',
		...options,
		registerCountry: '',
		registerUserId: 0,
		position: '',
	},
	getters: {
		getStepInformation(state) {
			return (payload) => {
				if (Object.keys(state).includes(payload)) {
					return state[payload];
				}
				return null;
			};
		},
		getCurrentStep(state) {
			return () => state.currentStep;
		},
		getCompletedSteps(state) {
			return () => state.completedSteps;
		},
		getFirstIncompleteStep(state) {
			return (startingStep = 1) => {
				let step = startingStep;
				while (state.completedSteps.includes(step)) {
					step += 1;
				}

				return step;
			};
		},
		getRegisterCountry(state) {
			return () => state.registerCountry;
		},
		getRegisterUserId(state) {
			return () => state.registerUserId;
		},
	},
	mutations: {
		setState(state, payload) {
			const [name] = Object.keys(payload);
			state[name] = payload[name];
		},
	},
	actions: {
		saveCart({ commit }, payload) {
			try {
				commit('setState', { cartId: payload });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		removeCart({ commit }) {
			commit('setState', { cartId: '' });
		},
		saveInfo(context, payload) {
			const { commit, state } = context;
			const [name] = Object.keys(payload);
			if (Object.keys(options).includes(name)) {
				const stepIndex = steps.findIndex((step) => Object.keys(step)[0] === name);
				commit('setState', { completedSteps: [...state.completedSteps, stepIndex] });
				commit('setState', payload);
				return Promise.resolve(true);
			}
			return Promise.reject(new Error('invalid_step'));
		},
		saveStepNumber({ commit }, payload) {
			try {
				commit('setState', { currentStep: payload });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		saveCountry({ commit }, name) {
			try {
				commit('setState', { registerCountry: name });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		saveUserId({ commit }, userId) {
			try {
				commit('setState', { registerUserId: userId });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		saveStepAsIncomplete(context, incompleteStep) {
			const { commit, state } = context;
			commit('setState', { completedSteps: [...state.completedSteps.filter((stepIndex) => stepIndex !== incompleteStep)] });
			return Promise.resolve(true);
		},
		removeInfo({ commit }, extraOptions = {}) {
			const { resetStepNumber = true, removeCartId = true } = extraOptions;
			Object.keys(options).forEach((item) => {
				const payload = {};
				payload[item] = {};
				commit('setState', payload);
			});
			commit('setState', { completedSteps: [] });
			commit('setState', { position: '' });
			if (removeCartId) {
				commit('setState', { cartId: '' });
			}
			if (resetStepNumber) {
				commit('setState', { currentStep: 0 });
			}
		},
		setPositionForRegister({ commit }, payload) {
			try {
				const { leg, parent, spillover } = payload;
				if (typeof leg !== 'undefined' && typeof parent !== 'undefined') {
					commit('setState', { position: { leg, parent, spillover } });
					return Promise.resolve(true);
				}
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		removePosition({ commit }) {
			commit('setState', { position: '' });
		},
	},
});

export default Store;
