const MainHeader = () => import('@/views/Layouts/Main/Header');
const MembershipRenewalContainer = () => import('@/views/Layouts/Main/MembershipRenewalContainer');

export default {
	path: '/onboarding',
	redirect: '/onboarding',
	name: 'OnboardingContainer',
	component: MembershipRenewalContainer,
	children: [
		{
			path: '/onboarding',
			name: 'OnboardingMain',
			redirect: '/onboarding',
			component: MainHeader,
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/onboarding',
					name: 'Onboarding',
					component: () => import('@/views/Onboarding'),
					meta: {
						auth: true,
					},
					props: true,
					children: [
						{
							path: '/onboarding/welcome-message',
							name: 'OnboardingWelcomeMessage',
							component: () => import('@/views/Onboarding/components/WelcomeMessageStep'),
							meta: {
								auth: true,
							},
						},
						{
							path: '/onboarding/update-account-info',
							name: 'OnboardingUpdateAccountInfo',
							component: () => import('@/views/Onboarding/components/UpdateAccountInfoStep'),
							meta: {
								auth: true,
							},
						},
						{
							path: '/onboarding/update-profile-info',
							name: 'OnboardingUpdateProfileInfo',
							component: () => import('@/views/Onboarding/components/UpdateProfileInfoStep'),
							meta: {
								auth: true,
							},
						},
						{
							path: '/onboarding/update-autoship-info',
							name: 'OnboardingAutoshipInfo',
							component: () => import('@/views/Onboarding/components/AutoshipInfoStep'),
							meta: {
								auth: true,
							},
						},
						{
							path: '/onboarding/replicated-site-info',
							name: 'OnboardingReplicatedSiteInfo',
							component: () => import('@/views/Onboarding/components/ReplicatedSiteInfoStep.vue'),
							meta: {
								auth: true,
							},
						},
						{
							path: '/onboarding/velovita-vibe',
							name: 'OnboardingMobileAppInfo',
							component: () => import('@/views/Onboarding/components/VelovitaVibeStep'),
							meta: {
								auth: true,
							},
						},
					],
				},
			],
		},
	],
};
