const Layout = () => import('@/views/Layouts/Purchase/CryptoPayment.vue');

export const CryptoPaymentDetails = {
	path: '/crypto_payments/details/:wallet_address',
	component: Layout,
	children: [
		{
			path: '/crypto_payments/details/:wallet_address',
			name: 'CryptoPaymentsDetails',
			component: () => import('@/views/CryptoPayments/Details'),
			meta: {
				auth: undefined,
			},
		},
	],
};

export default {};
