import { WHITELIST_TYPES, WHITELIST_MODULES, WHITELIST_CUSTOM_TYPES } from '@/settings/Alerts';
import { admin } from '@/settings/Roles';
import { TREE_TYPES } from '@/settings/Tree';

const PageNotFound = () => import('@/views/Pages/Page404.vue');
const Unautorized = () => import('@/views/Pages/Forbidden.vue');
const NoAuthHeader = () => import('@/views/Layouts/Main/Header/NoAuthHeader.vue');
const Header = () => import('@/views/Layouts/Main/Header');
const PurchaseHeader = () => import('@/views/Layouts/Purchase/Redirected');

export const Home = {
	path: '/home',
	name: 'MainHome',
	redirect: '/home',
	component: Header,
	children: [
		{
			path: '/home',
			name: 'Home',
			component: () => import('@/views/Dashboard'),
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/distributor-dashboard/:userId',
					name: 'AdminDistributorsDashboard',
					component: () => import('@/views/Dashboard/components/DistributorDashboard'),
					meta: {
						auth: true,
						section: 'dashboard',
						permissions: 'view',
					},
				},
				{
					path: '/agency-dashboard/:userId',
					name: 'AdminAgencyDashboard',
					component: () => import('@/views/Dashboard/components/AgencyDashboard.vue'),
					meta: {
						auth: true,
						section: 'agency_dashboard',
						permissions: 'view',
					},
				},
				{
					path: '/affiliate-dashboard/:userId',
					name: 'AdminAffiliateDashboard',
					component: () => import('@/views/Dashboard/components/AffiliateDashboard.vue'),
					meta: {
						auth: true,
						section: 'dashboard',
						permissions: 'view',
					},
				},
				{
					path: '/customer-dashboard/:userId',
					name: 'AdminCustomerDashboard',
					component: () => import('@/views/Dashboard/components/CustomerDashboard.vue'),
					meta: {
						auth: true,
						section: 'dashboard',
						permissions: 'view',
					},
				},
				{
					path: '/distributor-information/:userId',
					name: 'DistributorsInformation',
					component: () => import('@/views/Profile/DistributorsInformation'),
					meta: {
						auth: true,
						section: 'distributors_information',
						permissions: 'view',
					},
				},
				{
					path: '/agency-information/:userId',
					name: 'AgenciesInformation',
					component: () => import('@/views/Profile/DistributorsInformation'),
					meta: {
						auth: true,
						section: 'distributors_information', // TODO: Change this permission to agencies_information
						permissions: 'view',
					},
				},
				{
					path: '/customer-information/:userId',
					name: 'CustomersInformation',
					component: () => import('@/views/Users/CustomersInformation.vue'),
					meta: {
						auth: true,
						section: 'distributors_information', // TODO: Change this permission to customers_information
						permissions: 'view',
					},
				},
				{
					path: '/affiliate-information/:userId',
					name: 'AffiliatesInformation',
					component: () => import('@/views/Users/CustomersInformation.vue'),
					meta: {
						auth: true,
						section: 'distributors_information', // TODO: Change this permission to affiliates_information
						permissions: 'view',
					},
				},
				{
					path: '/user-orders/:userId',
					name: 'UsersMainOrders',
					component: () => import('@/views/Orders'),
					meta: {
						auth: true,
						section: 'orders',
						permissions: 'view',
					},
				},
				{
					path: '/distributor-downloads/:distributorId',
					name: 'DistributorsDownloads',
					component: () => import('@/views/Orders/Downloads'),
					meta: {
						auth: true,
						section: 'downloads',
						permissions: 'view',
					},
				},
				{
					path: '/distributor-commissions/:distributorId',
					name: 'DistributorsCommissions',
					component: () => import('@/views/Reports/DistributorsCommissions'),
					meta: {
						auth: true,
						section: 'commissions',
						permissions: 'view',
					},
				},
				{
					path: '/affiliate-commissions/:distributorId',
					name: 'AffiliatesCommissions',
					component: () => import('@/views/Reports/DistributorsCommissions'),
					meta: {
						auth: true,
						section: 'commissions',
						permissions: 'view',
					},
				},
				{
					path: '/distributor-bv/:distributorId',
					name: 'DistributorsBV',
					component: () => import('@/views/Reports/Bv'),
					meta: {
						auth: true,
						section: 'bv',
						permissions: 'view',
					},
				},
				{
					path: '/distributor-binary-tree/:userId',
					name: 'DistributorsBinaryTree',
					component: () => import('@/views/Network/BinaryTree'),
					meta: {
						auth: true,
						section: 'binary_tree',
						permissions: 'view',
					},
					props: () => ({ treeType: TREE_TYPES.BINARY }),
				},
				{
					path: '/distributor-unilevel-summary/:userId',
					name: 'DistributorsUnilevelSummary',
					component: () => import('@/views/Network/UnilevelSummaryData'),
					meta: {
						auth: true,
						section: 'unilevel_summary',
						permissions: 'view',
					},
				},
				{
					path: '/distributor-organization/:userId',
					name: 'DashboardDistributorOrganization',
					component: () => import('@/views/Network/DistributorOrganization'),
					meta: {
						auth: true,
						section: 'distributor_organization',
						permissions: 'view',
					},
				},
				{
					path: '/distributor-autoship-summary/:userId',
					name: 'DistributorsAutoshipSummary',
					component: () => import('@/views/Reports/AutoshipSummary'),
					meta: {
						auth: true,
						section: 'autoship_summary',
						permissions: 'view',
					},
				},
				{
					path: '/wallet-settings/:distributorId',
					name: 'UsersWalletSettings',
					component: () => import('@/views/Wallets/Settings'),
					meta: {
						auth: true,
						section: 'wallet_transactions',
						permissions: 'update_password',
					},
				},
				{
					path: '/user-notes/:userId',
					name: 'DashboardUserNotes',
					component: () => import('@/views/Reports/UserNotes'),
					meta: {
						auth: true,
						section: 'user_notes',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/home/:distributorId/distributor',
			name: 'DistributorDashboard',
			component: () => import('@/views/Dashboard'),
			meta: {
				auth: true,
				role: admin,
			},
		},
	],
};

export const Login = {
	path: '/login',
	component: NoAuthHeader,
	children: [
		{
			path: '/login',
			name: 'Login',
			component: () => import('@/views/Pages/Login'),
			meta: {
				auth: false,
			},
		},
	],
};

export const ReplicatedSite = {
	path: '/replicated-site/:replicatedId',
	component: NoAuthHeader,
	children: [
		{
			path: '/replicated-site/:replicatedId',
			component: () => import('@/views/Pages/ReplicatedSite'),
			name: 'ReplicatedSiteBar',
			meta: {
				auth: undefined,
			},
		},
	],
};

export const ForgotPassword = {
	path: '/forgot-password',
	redirect: '/forgot-password',
	component: NoAuthHeader,
	children: [
		{
			path: '/forgot-password',
			name: 'ForgotPassword',
			component: () => import('@/views/Users/ForgotPassword'),
			meta: {
				auth: false,
			},
		},
	],
};

export const RegisterAsCustomer = {
	path: '/become-a-customer',
	redirect: '/become-a-customer',
	component: NoAuthHeader,
	children: [
		{
			path: '/become-a-customer',
			name: 'RegisterAsCustomer',
			component: () => import('@/views/Users/RegisterAsCustomer'),
			meta: {
				auth: false,
			},
		},
	],
};

// export const OnboardingProcess = {
// 	path: '/onboarding-process',
// 	redirect: '/onboarding-process',
// 	component: NoAuthHeader,
// 	children: [
// 		{
// 			path: '/onboarding-process',
// 			name: 'OnboardingProcess',
// 			component: () => import('@/views/Users/OnboardingProcess'),
// 			meta: {
// 				auth: false,
// 			},
// 		},
// 	],
// };

export const CommonMessages = {
	path: '/messages',
	redirect: '/',
	component: NoAuthHeader,
	children: [
		{
			path: '/messages/:module/:type',
			name: 'EmailChanged',
			beforeEnter: (to, from, next) => {
				const { type, module } = to.params;
				if (WHITELIST_MODULES.includes(module)) {
					if (WHITELIST_TYPES.includes(type) || (WHITELIST_CUSTOM_TYPES[module] !== undefined && WHITELIST_CUSTOM_TYPES[module].includes(type))) {
						return next();
					}
				}
				return next('/404');
			},
			component: () => import('@/views/Pages/CommonErrors'),
			meta: {
				auth: undefined,
			},
		},
	],
};

export const Forbidden = {
	path: '/forbidden',
	component: Unautorized,
	name: 'Forbidden',
	meta: {
		auth: undefined,
	},
};

export const NotFound = {
	path: '*',
	component: PageNotFound,
	name: 'PageNotFound',
	meta: {
		auth: undefined,
	},
};

export const RedirectedRegister = {
	name: 'MainRedirectedRegister',
	path: '/redirected/register',
	component: PurchaseHeader,
	redirect: '/redirected/register/success',
	children: [
		{
			name: 'RedirectedRegisterSuccess',
			path: '/redirected/register/success',
			component: () => import('@/views/Pages/Redirected/RegisterSuccess'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedOxxoSuccessPending',
			path: '/redirected/register/oxxo_success_pending',
			component: () => import('@/views/Pages/Redirected/OxxoSuccessPending'),
			meta: {
				auth: undefined,
			},
		},
	],
};

export const RedirectedPurchase = {
	name: 'MainRedirectedPurchase',
	path: '/redirected/purchase',
	component: PurchaseHeader,
	redirect: '/redirected/purchase/success',
	children: [
		{
			name: 'RedirectedPurchaseSuccess',
			path: '/redirected/purchase/success',
			component: () => import('@/views/Pages/Redirected/PurchaseSuccess'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedPurchaseFailure',
			path: '/redirected/purchase/failure',
			component: () => import('@/views/Pages/Redirected/PurchaseFailure'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedOxxoSuccess',
			path: '/redirected/purchase/oxxo_success',
			redirect: { name: 'RedirectedPurchaseSuccess' },
		},
		{
			name: 'RedirectedOxxoFailure',
			path: '/redirected/purchase/oxxo_failure',
			component: () => import('@/views/Pages/Redirected/OxxoFailure'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedCreditCardSuccess',
			path: '/redirected/purchase/creditcard_success',
			component: () => import('@/views/Pages/Redirected/PurchaseCreditCardSuccess'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedCreditCardFailure',
			path: '/redirected/purchase/creditcard_failure',
			component: () => import('@/views/Pages/Redirected/PurchaseCreditCardFailure'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedUnionPaySuccess',
			path: '/redirected/purchase/unionpay_success',
			component: () => import('@/views/Pages/Redirected/PurchaseCreditCardSuccess'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedUnionPayFailure',
			path: '/redirected/purchase/unionpay_failure',
			component: () => import('@/views/Pages/Redirected/PurchaseCreditCardFailure'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedGCashSuccess',
			path: '/redirected/purchase/gcash_success',
			redirect: { name: 'RedirectedPurchaseSuccess' },
		},
		{
			name: 'RedirectedGCashFailure',
			path: '/redirected/purchase/gcash_failure',
			component: () => import('@/views/Pages/Redirected/GCashFailure'),
			meta: {
				auth: undefined,
			},
		},
		{
			name: 'RedirectedPayPalSuccess',
			path: '/redirected/purchase/paypal_success',
			redirect: { name: 'RedirectedPurchaseSuccess' },
		},
		{
			name: 'RedirectedPayPalFailure',
			path: '/redirected/purchase/paypal_failure',
			component: () => import('@/views/Pages/Redirected/PayPalFailure'),
			meta: {
				auth: undefined,
			},
		},
	],
};

export const CommandPanel = {
	path: '/command-panel',
	name: 'CommandPanel',
	component: () => import('@/views/CommandPanel'),
	meta: {
		auth: true,
		section: 'command_panel',
		permissions: 'view',
	},
};

export default {};
