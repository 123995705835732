const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/faq',
	name: 'MainFaq',
	redirect: '/faq',
	component: Header,
	children: [
		{
			path: '/faq',
			name: 'Faq',
			redirect: '/faq/backoffice-procedure',
			component: () => import('@/views/Faq'),
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/faq/search-results',
					name: 'FaqSearchResults',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
				{
					path: '/faq/backoffice-procedure',
					name: 'FaqBackofficeProcedure',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
				{
					path: '/faq/commissions-wallets',
					name: 'FaqCommissionsWallets',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
				{
					path: '/faq/general-information',
					name: 'FaqGeneralInformation',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
				{
					path: '/faq/comp-plan',
					name: 'FaqCompPlan',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
				{
					path: '/faq/orders-shipping',
					name: 'FaqOrdersShipping',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
				// {
				// 	path: '/faq/payment',
				// 	name: 'FaqPayment',
				// 	component: () => import('@/views/Faq'),
				// 	meta: {
				// 		auth: true,
				// 	},
				// },
				{
					path: '/faq/product-information',
					name: 'FaqProductInformation',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
				{
					path: '/faq/:other',
					name: 'FaqOther',
					redirect: '/faq/backoffice-procedure',
					component: () => import('@/views/Faq'),
					meta: {
						auth: true,
					},
				},
			],
		},
	],
};
