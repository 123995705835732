/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import { CHECKOUT_OLD_LOGGED_STEPS, CHECKOUT_OLD_GUEST_STEPS } from '@/settings/Wizard';
import store from '@/store/Login';

const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/checkout-old',
	name: 'CheckoutOld',
	redirect: () => (store.state.isLogged ? { name: Object.keys(CHECKOUT_OLD_LOGGED_STEPS)[0] } : { name: Object.keys(CHECKOUT_OLD_GUEST_STEPS)[0] }),
	component: Header,
	children: [
		{
			path: '/checkout-old/personal-information',
			name: 'CheckoutOldPersonalInformation',
			component: () => import('@/views/CheckoutOld/PersonalInformation'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/checkout-old/shipping',
			name: 'CheckoutOldShipping',
			component: () => import('@/views/CheckoutOld/Shipping'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/checkout-old/payment-method',
			name: 'CheckoutOldPaymentMethod',
			component: () => import('@/views/CheckoutOld/PaymentMethod'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/checkout-old/confirmation',
			name: 'CheckoutOldConfirmation',
			component: () => import('@/views/CheckoutOld/Confirmation'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/checkout-old/success',
			name: 'CheckoutOldSuccess',
			component: () => import('@/views/CheckoutOld/Success'),
			meta: {
				auth: undefined,
			},
		},
	],
};
