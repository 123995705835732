/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import store from '@/store/Login';

const Header = () => import('@/views/PhysicalStore/Layouts/Purchase');
export default {
	path: '/pickup/purchase',
	name: 'PSPurchase',
	redirect: () => (store.state.isLogged ? { name: 'PSPurchaseConfirmation' } : { name: 'PSStore' }),
	component: Header,
	children: [
		{
			path: '/pickup/purchase/confirmation',
			name: 'PSPurchaseConfirmation',
			component: () => import('@/views/PhysicalStore/Purchase/Confirmation'),
			meta: {
				auth: true,
				section: 'pickup_store',
				permissions: 'view',
			},
		},
		{
			path: '/pickup/purchase/success',
			name: 'PSPurchaseSuccess',
			component: () => import('@/views/PhysicalStore/Purchase/Success'),
			meta: {
				auth: true,
				section: 'pickup_store',
				permissions: 'view',
			},
			props: (route) => ({
				paymentMethodName: route.query.payment_method ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
				qrCode: route.params.qrCode ?? '',
			}),
		},
	],
};
