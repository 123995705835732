const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/orders/:order_id/change-payment-method',
	name: 'ChangePaymentMethodMain',
	redirect: { name: 'ChangePaymentMethod' },
	component: Header,
	children: [
		{
			path: '/orders/:order_id/change-payment-method',
			name: 'ChangePaymentMethod',
			component: () => import('@/views/ChangePaymentMethod/Confirmation'),
			meta: {
				auth: true,
				section: 'orders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/:order_id/change-payment-method/success',
			name: 'ChangePaymentMethodSuccess',
			component: () => import('@/views/ChangePaymentMethod/Success'),
			meta: {
				auth: true,
			},
			props: (route) => ({
				paymentMethodName: route.query.payment_method ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
			}),
		},
	],
};
