const Header = () => import('@/views/Layouts/Store');
export default {
	path: '/member-business-agreements',
	component: Header,
	children: [
		{
			path: '/member-business-agreements',
			name: 'DistributorBusinessAgreements',
			component: () => import('@/views/OfficialDocument'),
			meta: {
				auth: undefined,
			},
		},
	],
};
