export default {};

const BINARY = 'binary';
const UNILEVEL = 'unilevel';

export const TREE_TYPES = {
	BINARY,
	UNILEVEL,
};

export const PLACEMENTS = { l: 'left', r: 'right' };
export const ROWS = {
	xs: 2, sm: 2, md: 2, lg: 3, xl: 3, default: 3,
};
