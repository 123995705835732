import {
	ASC_ORDER as asc,
	DESC_ORDER as desc,
	ORDER_FIELD_NAME as field,
	ORDER_DIR_NAME as dir,
} from '@/settings/RequestReply';

import { COUNTRY_VAR_NAME, REGISTER_COUNTRY_VAR_NAME } from '@/settings/Country';
import { COUNTDOWN_UNITS } from '@/settings/Dates';

import store from '@/store';
import { REPLACEMENTS } from '@/settings/Multiselect';

const languageStore = process.env.VUE_APP_LANGUAGE_STORE;

const Common = {
	install(Vue) {
		const eventBus = new Vue();
		Vue.mixin({
			data() {
				return {
					country: this.getStoredCountry(),
					registerCountry: this.getRegisterCountry(),
					unitValues: COUNTDOWN_UNITS,
				};
			},
			watch: {
				country(value) {
					this.setStoredCountry(value);
					eventBus.$emit('changeCountry');
				},
				registerCountry(value) {
					this.setRegisterCountry(value);
					eventBus.$emit('changeRegisterCountry');
				},
			},
			mounted() {
				eventBus.$on('changeCountry', () => {
					this.country = this.getStoredCountry();
				});
				eventBus.$on('changeRegisterCountry', () => {
					this.registerCountry = this.getRegisterCountry();
				});
			},
			methods: {
				hasAccess(name, params) {
					const { route } = this.$router.resolve({ ...{ name }, ...{ params } });
					const { permissions, section } = route.meta;
					return this.$can(section, permissions);
				},
				hasAccessToPath(path) {
					const { route } = this.$router.resolve(path);
					const { permissions, section } = route.meta;
					return this.$can(section, permissions) || (typeof permissions === 'undefined' && typeof section === 'undefined');
				},
				hasAccessByRole(name, params) {
					const { route } = this.$router.resolve({ ...{ name }, ...{ params } });
					const { role } = route.meta;
					if (typeof role === 'undefined') {
						return true;
					}

					if (role instanceof Array) {
						const { type } = this.$user.details();
						return role.includes(type);
					}

					return false;
				},
				sortByField(fieldName) {
					let newSortDir = asc;
					if (this.$route.query[field] === fieldName && this.$route.query[dir] === asc) {
						newSortDir = desc;
					}

					// Actualizar la consulta en la URL
					const newQuery = { ...this.$route.query, [dir]: newSortDir, [field]: fieldName };
					this.$router.replace({ query: newQuery });
				},
				getStoredCountry() {
					let response = null;
					if (languageStore === 'COOKIE') {
						response = this.$cookie.get(COUNTRY_VAR_NAME);
					}
					if (languageStore === 'LOCALSTORAGE') {
						response = localStorage.getItem(COUNTRY_VAR_NAME);
					}
					if (store.getters['login/user'] && store.getters['login/user'].country && (response === null || response !== store.getters['login/user'].country.iso_code_2)) {
						response = store.getters['login/user'].country.iso_code_2;
					}
					return response;
				},
				setStoredCountry(code) {
					let value = code;
					if (!value) {
						value = null;
					}

					if (languageStore === 'COOKIE') {
						this.$cookie.set(COUNTRY_VAR_NAME, value);
					}
					if (languageStore === 'LOCALSTORAGE') {
						localStorage.setItem(COUNTRY_VAR_NAME, value);
					}
				},
				getRegisterCountry() {
					let response = null;

					if (languageStore === 'COOKIE') {
						response = this.$cookie.get(REGISTER_COUNTRY_VAR_NAME);
					}
					if (languageStore === 'LOCALSTORAGE') {
						response = localStorage.getItem(REGISTER_COUNTRY_VAR_NAME);
					}

					return response;
				},
				setRegisterCountry(code) {
					let value = code;
					if (!value) {
						value = null;
					}

					if (languageStore === 'COOKIE') {
						this.$cookie.set(REGISTER_COUNTRY_VAR_NAME, value);
					}
					if (languageStore === 'LOCALSTORAGE') {
						localStorage.setItem(REGISTER_COUNTRY_VAR_NAME, value);
					}
				},
				setRegisterPositionValues(code) {
					const { parent, placement, spillover } = code;
					if (typeof placement === 'undefined' || typeof parent === 'undefined' || typeof spillover === 'undefined') {
						if (languageStore === 'COOKIE') {
							this.$cookie.remove('register_parent');
							this.$cookie.remove('register_placement');
							this.$cookie.remove('register_spillover');
						}
						if (languageStore === 'LOCALSTORAGE') {
							localStorage.removeItem('register_parent');
							localStorage.removeItem('register_placement');
							localStorage.removeItem('register_spillover');
						}
						return;
					}

					if (languageStore === 'COOKIE') {
						this.$cookie.set('register_parent', parent);
						this.$cookie.set('register_placement', placement);
						this.$cookie.set('register_spillover', spillover);
					}
					if (languageStore === 'LOCALSTORAGE') {
						localStorage.setItem('register_parent', parent);
						localStorage.setItem('register_placement', placement);
						localStorage.setItem('register_spillover', spillover);
					}
				},
				getRegisterPositionValues() {
					let response = {};
					let parent = null;
					let placement = null;
					let spillover = null;

					if (languageStore === 'COOKIE') {
						parent = this.$cookie.get('register_parent');
						placement = this.$cookie.get('register_placement');
						spillover = this.$cookie.get('register_spillover') === 'true';
					}
					if (languageStore === 'LOCALSTORAGE') {
						parent = localStorage.getItem('register_parent');
						placement = localStorage.getItem('register_placement');
						spillover = localStorage.getItem('register_spillover') === 'true';
					}

					if (parent !== null && placement !== null && spillover !== null) {
						response = { parent, placement, spillover };
					}

					return response;
				},
				setFocus(id) {
					this.$nextTick(() => {
						if (document.getElementById(id)) {
							const type = document.getElementById(id).nodeName;
							if (type === 'INPUT' || type === 'TEXTAREA') {
								document.getElementById(id).focus();
							} else if (type !== 'INPUT' && document.getElementById(id).querySelector('input')) {
								document.getElementById(id).querySelector('input').focus();
							}
						}
					});
				},
				getDateDiffCountdown(date1, date2) {
					let diffMilliseconds = Math.abs(new Date(date1) - new Date(date2));
					const milliseconds = diffMilliseconds;

					const countdown = {};

					Object.keys(this.unitValues).forEach((unit) => {
						countdown[unit] = Math.trunc(diffMilliseconds / this.unitValues[unit]);
						if (countdown[unit]) {
							diffMilliseconds -= countdown[unit] * this.unitValues[unit];
						}
					});

					return { milliseconds, countdown };
				},
				createUuid() {
					/* eslint-disable */
					let dt = new Date().getTime();
					const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
						const r = (dt + Math.random() * 16) % 16 | 0;
						dt = Math.floor(dt / 16);
						return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
					});
					/* eslint-enable */
					return uuid;
				},
				siftEnabled() {
					return process.env.VUE_APP_ENV !== 'local' && process.env.VUE_APP_CC_PAYMENT_PROCESSOR === 'LPT';
				},
				fixDiacriticCharacters(originalText) {
					let newText = originalText;
					Object.entries(REPLACEMENTS).forEach(([replaceFor, diacriticCharacters]) => {
						diacriticCharacters.forEach((character) => {
							newText = newText.replaceAll(character, replaceFor);
						});
					});
					return newText;
				},
				objectToFormData(obj, form, namespace) {
					const formData = form || new FormData();
					let formKey;

					Object.keys(obj).forEach((property) => {
						if (namespace) {
							formKey = `${namespace}[${property}]`;
						} else {
							formKey = property;
						}

						// if the property is an object, but not a File, use recursivity.
						if (typeof obj[property] === 'object' && !(obj[property] instanceof File) && obj[property] !== null) {
							this.objectToFormData(obj[property], formData, formKey);
						} else {
							// if it's a string or a File object
							let value = obj[property];
							if (value === true || value === false) {
								value = value ? 1 : 0;
							}
							if (typeof value !== 'undefined' && value !== null) {
								formData.append(formKey, value);
							}
						}
					});

					return formData;
				},
				isJns() {
					return Number(this.$cookie.get('is_jns'));
				},
				logoIds() {
					const isJns = this.isJns();
					return {
						navbar: isJns ? 'jns_logo_navbar' : 'logo_navbar',
						navbarDark: isJns ? 'jns_logo_white' : 'logo_white',
						login: isJns ? 'jns_logo_card' : 'logo_card',
					};
				},
				handleSourceQueryParam() {
					const { query } = this.$route;
					const { source } = query;
					if (source) {
						this.$cookie.set('is_jns', source === '2422' ? 1 : 0);
						// Replace the current route to delete the 'source' query param
						delete query.source;
						window.history.pushState(null, null, this.$router.resolve({ query }).href);
					}
				},
			},
		});
	},
};
export default Common;
