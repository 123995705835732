const position = {
	namespaced: true,
	state: {
		register: {},
	},
	getters: {
		positionData(state) {
			return (field) => {
				if (typeof state[field] === 'undefined') {
					return false;
				}
				return state[field];
			};
		},
	},
	mutations: {
		setRegister(state, payload) {
			const { parent, placement, spillover } = payload;
			state.register = { placement, parent, spillover };
		},
		deleteRegister(state) {
			state.register = {};
		},
	},
	actions: {
		registerInPosition({ commit }, payload) {
			const { parent, placement, spillover } = payload;
			if (typeof placement === 'undefined' || typeof parent === 'undefined') {
				return Promise.reject(new Error('invalid_user'));
			}
			commit('setRegister', { placement, parent, spillover });
			return Promise.resolve(true);
		},
		onlyRegisterPosition({ commit }, payload) {
			const { placement } = payload;
			if (typeof placement === 'undefined') {
				return Promise.reject(new Error('invalid_placement'));
			}
			commit('setRegister', { placement });
			return Promise.resolve(true);
		},
		removeRegister({ commit }) {
			commit('deleteRegister');
		},
	},
};

export default position;
