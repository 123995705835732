const Header = () => import('@/views/Layouts/Main/Header');

export default {
	path: '/custom-prices',
	name: 'MainCustomPrices',
	redirect: '/custom-prices',
	component: Header,
	children: [
		{
			path: '/custom-prices',
			name: 'CustomPrices',
			redirect: '/custom-prices/developed',
			component: () => import('@/views/CustomPrices'),
			meta: {
				auth: true,
				section: 'custom_prices',
				permissions: 'view',
			},
			children: [
				{
					path: '/custom-prices/developed',
					name: 'CustomPricesDeveloped',
					component: () => import('@/views/CustomPrices/CustomPrices.vue'),
					meta: {
						auth: true,
						section: 'custom_prices',
						permissions: 'view',
					},
				},
				{
					path: '/custom-prices/emerging',
					name: 'CustomPricesEmerging',
					component: () => import('@/views/CustomPrices/CustomPrices.vue'),
					meta: {
						auth: true,
						section: 'custom_prices',
						permissions: 'view',
					},
				},
			],
		},
	],
};
