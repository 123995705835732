export const windowSizes = [
	{
		maxSize: 576,
		name: 'xs',
	},
	{
		maxSize: 768,
		name: 'sm',
	},
	{
		maxSize: 992,
		name: 'md',
	},
	{
		maxSize: 1200,
		name: 'lg',
	},
];

export const deviceResolutions = {
	ipad: {
		width: 1024,
		height: 768,
	},
};

export default {};
