const active = 'active';
const used = 'used';
const expired = 'expired';

export const CUSTOM_CARTS_STATUSES = {
	active,
	used,
	expired,
};

export default {};
