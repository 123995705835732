/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import {
	USERS,
	UPDATE_USER,
	USER_DATA,
	CHANGE_USER_PASSWORD,
	NEW_USER,
	GET_CORPORATES,
	GET_CUSTOMERS,
	USER_EXISTS,
	FETCH_USER,
	NEW_DISTRIBUTOR,
	GET_TFA_QR_CODE,
	ENABLE_TFA,
	DISABLE_TFA,
	UPGRADE_TO_AFFILIATE,
	UPDATE_LANGUAGE,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class User {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getUsers(options) {
		const { method, endpoint } = USERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	static userExists(user) {
		const axios = new Req();
		const { method, endpoint } = USER_EXISTS;
		return axios[method](endpoint(user)).then((response) => response).catch((error) => Promise.reject(error));
	}

	getCorporates(options) {
		const { method, endpoint } = GET_CORPORATES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCustomers(options) {
		const { method, endpoint } = GET_CUSTOMERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	loadUser(id) {
		const { method, endpoint } = USER_DATA;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createUser(payload) {
		const { method, endpoint } = NEW_USER;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createDistributor(payload) {
		const { method, endpoint } = NEW_DISTRIBUTOR;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateUser(id, payload) {
		const { method, endpoint } = UPDATE_USER;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	changePassword(id, payload) {
		const { method, endpoint } = CHANGE_USER_PASSWORD;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((errors) => Promise.reject(errors));
	}

	fetchUser(queryParams = {}) {
		const { method, endpoint } = FETCH_USER;
		const params = Req.setFilters(queryParams);
		return this.data[method](endpoint(params)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTfaQrCode() {
		const { method, endpoint } = GET_TFA_QR_CODE;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	enableTfa(payload) {
		const { method, endpoint } = ENABLE_TFA;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	disableTfa(payload) {
		const { method, endpoint } = DISABLE_TFA;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	becomeAffiliate(payload) {
		const { method, endpoint } = UPGRADE_TO_AFFILIATE;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateLanguage(payload) {
		const { method, endpoint } = UPDATE_LANGUAGE;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default User;
