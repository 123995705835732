const Header = () => import('@/views/Layouts/Store');

export default {
	path: '/member-rewards',
	component: Header,
	children: [
		{
			path: '/member-rewards',
			name: 'MemberRewards',
			component: () => import('@/views/MemberRewards'),
			meta: {
				auth: undefined,
			},
		},
	],
};
