/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import { AGENCY_REPLICATED_SITE_PATHS } from '@/settings/Replicated';
import { PURCHASE_LOGGED_STEPS } from '@/settings/Wizard';
import store from '@/store/Login';
import { formatPathName } from '@/util/AgencySite';

const path = AGENCY_REPLICATED_SITE_PATHS.meta;
const Header = () => import('@/views/Layouts/Purchase');

export default {
	path: `/${path}/checkout`,
	name: formatPathName(path, 'Checkout'),
	redirect: () => (store.state.isLogged ? { name: Object.keys(PURCHASE_LOGGED_STEPS)[0] } : { name: formatPathName(path, 'CheckoutConfirmation') }),
	component: Header,
	children: [
		{
			path: `/${path}/checkout/confirmation`,
			name: formatPathName(path, 'CheckoutConfirmation'),
			component: () => import('@/views/Checkout/Confirmation'),
			meta: {
				auth: false,
			},
		},
		{
			path: `/${path}/checkout/success`,
			name: formatPathName(path, 'CheckoutSuccess'),
			component: () => import('@/views/Checkout/Success'),
			meta: {
				auth: false,
			},
			props: (route) => ({
				// paymentMethodName: route.query.payment_method ?? '',
				orderId: route.query.order_id ?? '',
				orderTotal: route.query.order_total ?? '',
				ecommercePath: route.query.ecommerce_path ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
			}),
		},
	],
};
