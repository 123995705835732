/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import { CHECKOUT_GUEST_STEPS, PURCHASE_LOGGED_STEPS } from '@/settings/Wizard';
import store from '@/store/Login';

const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/checkout',
	name: 'Checkout',
	redirect: () => (store.state.isLogged ? { name: Object.keys(PURCHASE_LOGGED_STEPS)[0] } : { name: Object.keys(CHECKOUT_GUEST_STEPS)[0] }),
	component: Header,
	children: [
		{
			path: '/checkout/confirmation',
			name: 'CheckoutConfirmation',
			component: () => import('@/views/Checkout/Confirmation'),
			meta: {
				auth: false,
			},
		},
		{
			path: '/checkout/success',
			name: 'CheckoutSuccess',
			component: () => import('@/views/Checkout/Success'),
			meta: {
				auth: false,
			},
			props: (route) => ({
				// paymentMethodName: route.query.payment_method ?? '',
				orderId: route.query.order_id ?? '',
				orderTotal: route.query.order_total ?? '',
				ecommercePath: route.query.ecommerce_path ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
			}),
		},
	],
};
