const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/agency-promotions',
	name: 'MainAgencyPromotions',
	redirect: '/agency-promotions',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/agency-promotions',
			name: 'AgencyPromotions',
			component: () => import('@/views/AgencyPromotions'),
			meta: {
				auth: true,
				section: 'agency_promo',
				permissions: 'view',
			},
		},
	],
};
