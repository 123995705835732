const Header = () => import('@/views/Layouts/Store');
export default {
	path: '/store',
	name: 'MainStore',
	redirect: '/store',
	component: Header,
	children: [
		{
			path: '/store',
			name: 'Store',
			component: () => import('@/views/Store/'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/upgrade',
			name: 'Upgrade',
			redirect: {
				name: 'PurchasePack',
			},
			meta: {
				auth: true,
			},
		},
		{
			path: '/landing-pages/bran',
			name: 'BranLandingPage',
			component: () => import('@/views/LandingPages/Bran'),
		},
		{
			path: '/landing-pages/zlem',
			name: 'ZlemLandingPage',
			component: () => import('@/views/LandingPages/Zlem'),
		},
		{
			path: '/landing-pages/uuth',
			name: 'UuthLandingPage',
			component: () => import('@/views/LandingPages/Uuth'),
		},
		{
			path: '/landing-pages/plos',
			name: 'PlosLandingPage',
			component: () => import('@/views/LandingPages/Plos'),
		},
		{
			path: '/landing-pages/byom',
			name: 'ByomLandingPage',
			component: () => import('@/views/LandingPages/Byom'),
		},
		{
			path: '/landing-pages/lite',
			name: 'LiteLandingPage',
			component: () => import('@/views/LandingPages/Lite'),
		},
		{
			path: '/landing-pages/tuun',
			name: 'TuunLandingPage',
			component: () => import('@/views/LandingPages2/Tuun'),
		},
		{
			path: '/landing-pages/opportunity-1',
			name: 'Opportunity1LandingPage',
			component: () => import('@/views/LandingPages/Opportunity1'),
		},
		{
			path: '/landing-pages/opportunity-2',
			name: 'Opportunity2LandingPage',
			component: () => import('@/views/LandingPages/Opportunity2'),
		},
		{
			path: '/landing-pages/healthy-weight-loss',
			name: 'HealthyWeightLossLandingPage',
			component: () => import('@/views/LandingPages2/HealthyWeightLoss'),
		},
		{
			path: '/replicated-site-unavailable',
			name: 'ReplicatedSiteNotAvailable',
			component: () => import('@/views/Pages/ReplicatedSiteNotAvailable'),
		},
	],
};
