/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
/* eslint arrow-parens: ["error", "always"] */

import axios from 'axios';
import router from '@/router';
import {
	AUTH, THROTTLE, ACCESS, SYSTEM_ALERT,
} from '@/settings/Errors';
import { PAGINATION } from '@/settings/RequestReply';
import { DATE_RANGE_FILTER } from '@/settings/Dates';
import { COUNTRY_VAR_NAME, REGISTER_COUNTRY_VAR_NAME, REGISTER_COUNTRY_ROUTE_NAMES } from '@/settings/Country';
import store from '@/store';
import Alert from '@/util/Alert';
import { Validations as Translation } from '@/translations';

const alert = new Alert();
const cookie = require('vue-cookie');

const authStore = process.env.VUE_APP_AUTH_STORE;
const languageStore = process.env.VUE_APP_LANGUAGE_STORE;
const defaultLanguage = process.env.VUE_APP_LANG;
const configInstance = { baseURL: process.env.VUE_APP_API_PREFIX };

const instances = {
	api: axios.create(configInstance),
	apiFilters: axios.create(configInstance),
	thirdParty: axios.create(),
};

function getToken() {
	// Checking for the exists of authorization token
	let authToken = '';
	if (authStore === 'COOKIE') {
		authToken = cookie.get('default_auth_token');
	}
	if (authStore === 'LOCALSTORAGE') {
		authToken = localStorage.getItem('default_auth_token');
	}
	return authToken;
}

function getLanguage() {
	let language = defaultLanguage;
	if (languageStore === 'COOKIE') {
		language = cookie.get('vue-lang');
	} if (languageStore === 'LOCALSTORAGE') {
		language = localStorage.getItem('vue-lang');
	}
	return language;
}

function getCountry() {
	let response = null;
	let tempCountry = null;
	if (languageStore === 'COOKIE') {
		response = this.$cookie.get(COUNTRY_VAR_NAME);
		tempCountry = this.$cookie.get(REGISTER_COUNTRY_VAR_NAME);
	}
	if (languageStore === 'LOCALSTORAGE') {
		response = localStorage.getItem(COUNTRY_VAR_NAME);
		tempCountry = localStorage.getItem(REGISTER_COUNTRY_VAR_NAME);
	}

	if (REGISTER_COUNTRY_ROUTE_NAMES.includes(router.currentRoute.name) && tempCountry !== null) {
		response = tempCountry;
	} else if (store.getters['login/user'] && store.getters['login/user'].country && (response === null || response !== store.getters['login/user'].country.iso_code_2)) {
		response = store.getters['login/user'].country.iso_code_2;
	}
	return response;
}

function handleError(error) {
	const { response } = error;

	if (AUTH.includes(response.status)) {
		store.dispatch('login/logout');
		router.push({ name: 'Login' }).catch(() => {});
	} else if (THROTTLE.includes(response.status)) {
		alert.toast('error', Translation[getLanguage()].throttle_title);
	} else if (ACCESS.includes(response.status)) {
		document.location.reload();
	} else if (SYSTEM_ALERT.includes(response.status)) {
		alert.toast('error', Translation[getLanguage()].common_response_error);
	}
}

export const getURLParams = function getURLParams(query) {
	if (typeof query !== 'object') {
		return '';
	}
	if (query[DATE_RANGE_FILTER] && query[DATE_RANGE_FILTER] === 'custom') {
		delete query[DATE_RANGE_FILTER];
	}
	const response = Object.keys(query).map((item) => `${item}=${encodeURIComponent(query[item])}`).join('&');

	if (response) return `?${response}`;

	return `${response}`;
};

instances.api.interceptors.request.use((config) => {
	const authToken = getToken();
	config.headers['X-localization'] = getLanguage();
	config.headers['X-country'] = getCountry();
	if (typeof authToken === 'string' && authToken.length) {
		config.headers.authorization = `Bearer ${authToken}`;
	}
	return config;
}, (error) => Promise.reject(error));

instances.api.interceptors.response.use((response) => response, (error) => {
	handleError(error);
	return Promise.reject(error);
});

instances.apiFilters.interceptors.request.use((config) => {
	const authToken = getToken();
	config.headers['X-localization'] = getLanguage();
	config.headers['X-country'] = getCountry();
	if (typeof authToken === 'string' && authToken.length) {
		config.headers.authorization = `Bearer ${authToken}`;
	}
	const { query } = router.currentRoute;
	const params = getURLParams(query);

	let filters = '';
	if (config.url.includes('?')) {
		[config.url, filters] = config.url.split('?');
	}

	const urlParams = new URLSearchParams(params);
	if (filters !== '') {
		const searchParams = new URLSearchParams(filters);
		searchParams.forEach((value, key) => {
			if (!urlParams.has(key)) {
				urlParams.set(key, value);
			}
		});
	}

	if (config.method === 'get' && urlParams.toString()) {
		config.url += `?${urlParams.toString()}`;
	}
	return config;
}, (error) => Promise.reject(error));

instances.apiFilters.interceptors.response.use((response) => {
	try {
		const totalPages = response.data.meta.pagination.total_pages;
		const { query } = router.currentRoute;
		if (typeof query[PAGINATION] !== 'undefined' && query[PAGINATION] > totalPages) {
			query[PAGINATION] = totalPages;
			router.replace('?');
			router.replace({ name: router.currentRoute.name, params: router.currentRoute.params, query });
		}
	} catch (error) {
		return response;
	}
	return response;
}, (error) => {
	handleError(error);
	return Promise.reject(error);
});

export const { api, apiFilters, thirdParty } = instances;
export default instances;
