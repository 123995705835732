const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/corporate-dashboard',
	name: 'MainAdminDashboard',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/corporate-dashboard',
			name: 'AdminDashboard',
			component: () => import('@/views/AdminDashboard'),
			meta: {
				auth: true,
				section: 'business_dashboard',
				permissions: 'view',
			},
		},
	],
};
