const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/profile',
	name: 'MainProfile',
	redirect: '/profile',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/profile',
			name: 'Profile',
			redirect: '/profile/personal-information',
			component: () => import('@/views/Profile'),
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/profile/personal-information',
					name: 'PersonalInformation',
					component: () => import('@/views/Profile/PersonalInformation'),
					meta: {
						auth: true,
					},
				},
				{
					path: '/profile/account-information',
					name: 'AccountInformation',
					component: () => import('@/views/Profile/AccountInformation'),
					meta: {
						auth: true,
						role: ['customer', 'affiliate', 'distributor', 'pre_distributor'],
					},
				},
				{
					path: '/profile/replicated-site',
					name: 'ReplicatedSite',
					component: () => import('@/views/Profile/ReplicatedSite'),
					meta: {
						auth: true,
						role: ['affiliate', 'distributor', 'pre_distributor'],
					},
				},
				{
					path: '/profile/agency/replicated-site',
					name: 'AgencyReplicatedSite',
					component: () => import('@/views/Profile/AgencyReplicatedSite'),
					meta: {
						auth: true,
						role: ['agency'],
					},
				},
				{
					path: '/profile/co-applicants',
					name: 'CoApplicants',
					component: () => import('@/views/Profile/CoApplicants'),
					meta: {
						auth: true,
						role: ['distributor', 'pre_distributor'],
					},
				},
				{
					path: '/profile/address_book',
					name: 'AddressBook',
					component: () => import('@/views/Profile/Addresses/AddressBook'),
					meta: {
						auth: true,
						role: ['customer', 'affiliate', 'distributor'],
					},
				},
				{
					path: '/profile/credit_card',
					name: 'CreditCard',
					component: () => import('@/views/Profile/CreditCard'),
					meta: {
						auth: true,
						role: ['customer', 'affiliate', 'distributor'],
					},
					children: [
						{
							path: '/profile/credit_card/all',
							name: 'AllCreditCards',
							component: () => import('@/views/Profile/CreditCard/AllCreditCard'),
							meta: {
								auth: true,
								role: ['customer', 'affiliate', 'distributor'],
							},
						},
						{
							path: '/profile/credit_card/backup',
							name: 'BackupCreditCards',
							component: () => import('@/views/Profile/CreditCard/BackupCreditCard'),
							meta: {
								auth: true,
								role: ['customer', 'affiliate', 'distributor'],
							},
						},
					],
				},
				{
					path: '/profile/credit_card/:credit_card_id/update_credit_card',
					name: 'UpdateProfileCreditCard',
					component: () => import('@/views/Profile/CreditCard/UpdateCreditCard'),
					meta: {
						auth: true,
						role: ['customer', 'affiliate', 'distributor'],
					},
				},
				{
					path: '/profile/address_book/new_address',
					name: 'NewProfileAddress',
					component: () => import('@/views/Profile/Addresses/NewAddress'),
					meta: {
						auth: true,
						role: ['customer', 'affiliate', 'distributor'],
					},
				},
				{
					path: '/profile/credit_card/new_credit_card',
					name: 'NewProfileCreditCard',
					component: () => import('@/views/Profile/CreditCard/components/NewCreditCard'),
					meta: {
						auth: true,
						role: ['customer', 'affiliate', 'distributor'],
					},
				},
				{
					path: '/profile/address_book/:address_id/update_address',
					name: 'UpdateProfileAddress',
					component: () => import('@/views/Profile/Addresses/UpdateAddress'),
					meta: {
						auth: true,
						role: ['customer', 'affiliate', 'distributor'],
					},
				},
			],
		},
		{
			path: '/change-my-password',
			name: 'UpdateMyPassword',
			component: () => import('@/views/Users/ChangePassword'),
			meta: {
				auth: true,
			},
		},
		// {
		// 	path: '/two-factor-authentication',
		// 	name: 'TwoFactorAuthentication',
		// 	component: () => import('@/views/Users/TwoFactorAuthentication'),
		// 	meta: {
		// 		auth: true,
		// 	},
		// },
		{
			path: '/upgrade-to-affiliate',
			name: 'UpgradeCustomerToAffiliate',
			component: () => import('@/views/Users/UpgradeCustomerToAffiliate'),
			meta: {
				auth: true,
				role: ['customer'],
			},
		},
	],
};
