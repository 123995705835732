// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import cssVars from 'css-vars-ponyfill'

// Libraries
import 'core-js/features/promise';
import 'core-js/features/string';
import 'core-js/features/array';
import BootstrapVue from 'bootstrap-vue';
import moment from 'moment';
import 'moment-timezone';
import Vue from 'vue';
import vueAxios from 'vue-axios';
import VueGtm from '@gtm-support/vue2-gtm';
import VueMomentJS from 'vue-momentjs';
import vueNumeralFilterInstaller from 'vue-numeral-filter';

// Root Folder
import Clipboard from 'v-clipboard';
// import VueGtag from 'vue-gtag';

// Meta
import VueMeta from 'vue-meta';

import App from '@/App';

// Components
import Search from '@/components/Search';

// Config
import instances from '@/config/axios/index';

// Mixins
import Auth from '@/mixins/Auth';
import CommonMixin from '@/mixins/Common';
import Login from '@/mixins/Login';
import LogoutTimer from '@/mixins/LogoutTimer';

// Router
import router from '@/router';

// Store
import store from '@/store/index';

// Translations
import { Common } from '@/translations';

// Utils
import Alert from '@/util/Alert';
import Form from '@/util/Form';
import { canTrack, getIgnoredRoutes } from '@/util/GoogleTagManager';
import MultiLanguage from '@/util/Multilanguage';
import Replicated from '@/mixins/Replicated';

if (process.env.NODE_ENV === 'production') {
	console.log = () => null;
	console.warn = () => null;
}

// if (process.env.VUE_APP_ENV === 'production') {
// 	Vue.use(VueGtag, {
// 		config: { id: process.env.VUE_APP_GTAG_MEASUREMENT_ID },
// 		appName: process.env.VUE_APP_NAME,
// 	}, router);
// }

if (process.env.VUE_APP_ENV !== 'local' && process.env.VUE_APP_CC_PAYMENT_PROCESSOR === 'LPT') {
	router.afterEach(() => {
		console.log('Route changed');
		// eslint-disable-next-line
		_sift.push(['_trackPageview']);
	});
}

Vue.use(require('vue-cookie'));

Vue.use(Auth);
Vue.use(CommonMixin);
Vue.use(BootstrapVue);
Vue.use(Login);
Vue.use(Replicated);
Vue.use(MultiLanguage, Common);
Vue.use(VueMomentJS, moment);
Vue.use(vueAxios, instances);
Vue.use(VueMeta);

canTrack().then((response) => {
	if (response) {
		Vue.use(VueGtm, {
			id: process.env.VUE_APP_GTM_CONTAINER_ID.split(','),
			defer: false,
			compatibility: false,
			enabled: true,
			vueRouter: router,
			ignoredViews: getIgnoredRoutes(router.options.routes),
		});
	}
});

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.filter('capitalize', (string) => string.toUpperCase());

Vue.component('Search', Search);
Vue.use(Clipboard);

Vue.prototype.$store = store;
Vue.prototype.$Form = Form;
Vue.prototype.$Alert = Alert;

/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	components: {
		App,
	},
	mixins: [LogoutTimer],
	watch: {
		language(value) {
			this.$moment.locale(value);
		},
	},
	created() {
		this.$moment.locale(this.language);
	},
	template: '<App/>',
});
