const Header = () => import('@/views/PhysicalStore/Layouts/Store');
export default {
	path: '/pickup/product-details',
	name: 'PSOtherProducts',
	redirect: '/pickup/product-details',
	component: Header,
	children: [
		{
			path: '/pickup/product-details/:productName?',
			name: 'PSProductDetails',
			component: () => import('@/views/PhysicalStore/ProductDetails'),
			meta: {
				auth: undefined,
				section: 'pickup_store',
				permissions: 'view',
			},
		},
	],
};
