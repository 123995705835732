/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { REGISTER_STEPS as steps } from '@/settings/Wizard';
import { distributor } from '@/settings/Roles';
import User from '@/util/User';
import store from '@/store/index';

const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/register',
	name: 'Register',
	redirect: { name: Object.keys(steps)[0] },
	component: Header,
	beforeEnter: (to, from, next) => {
		const { isLogged } = store.state.login;
		if (isLogged) {
			const userData = new User();
			userData.fetchUser().then((response) => {
				const { type } = response.response;
				return type === distributor ? next() : next('/store');
			}).catch(() => next('/store'));
		}
		next();
	},
	children: [
		{
			path: '/register/pack',
			name: 'RegisterPack',
			component: () => import('@/views/Register/Pack'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/register/confirmation',
			name: 'RegisterConfirmation',
			component: () => import('@/views/Register/Confirmation'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/register/success',
			name: 'RegisterSuccess',
			component: () => import('@/views/Register/Success'),
			meta: {
				auth: undefined,
			},
			props: (route) => ({
				paymentMethodName: route.query.payment_method ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
			}),
		},
	],
};
