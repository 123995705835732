const Header = () => import('@/views/Layouts/Store');
export default {
	path: '/cart',
	name: 'MainCart',
	redirect: '/cart',
	component: Header,
	children: [
		{
			path: '/cart',
			name: 'Cart',
			component: () => import('@/views/Cart'),
			meta: {
				auth: undefined,
			},
		},
	],
};
