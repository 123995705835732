import { CUSTOM_CARTS_STATUSES } from '@/settings/CustomCarts';

const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/personalized-carts',
	name: 'MainCustomCarts',
	redirect: '/personalized-carts',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/personalized-carts',
			name: 'CustomCarts',
			redirect: '/personalized-carts/active',
			component: () => import('@/views/CustomCarts'),
			meta: {
				auth: true,
				section: 'custom_carts',
				permissions: 'view',
			},
			children: [
				{
					path: '/personalized-carts/active',
					name: 'CustomCartsActive',
					component: () => import('@/views/CustomCarts/List'),
					meta: {
						auth: true,
						section: 'custom_carts',
						permissions: 'view',
					},
					props: {
						status: CUSTOM_CARTS_STATUSES.active,
					},
				},
				{
					path: '/personalized-carts/used',
					name: 'CustomCartsUsed',
					component: () => import('@/views/CustomCarts/List'),
					meta: {
						auth: true,
						section: 'custom_carts',
						permissions: 'view',
					},
					props: {
						status: CUSTOM_CARTS_STATUSES.used,
					},
				},
				{
					path: '/personalized-carts/expired',
					name: 'CustomCartsExpired',
					component: () => import('@/views/CustomCarts/List'),
					meta: {
						auth: true,
						section: 'custom_carts',
						permissions: 'view',
					},
					props: {
						status: CUSTOM_CARTS_STATUSES.expired,
					},
				},
			],
		},
		{
			path: '/personalized-carts/generate',
			name: 'CustomCartsGenerate',
			component: () => import('@/views/CustomCarts/Generate'),
			meta: {
				auth: true,
				section: 'custom_carts',
				permissions: 'create',
			},
		},
	],
};
