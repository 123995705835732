/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { REGISTER_OLD_STEPS as steps } from '@/settings/Wizard';
import { distributor } from '@/settings/Roles';
import User from '@/util/User';
import store from '@/store/index';

const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/register-old',
	name: 'RegisterOld',
	redirect: { name: Object.keys(steps)[0] },
	component: Header,
	beforeEnter: (to, from, next) => {
		const { isLogged } = store.state.login;
		if (isLogged) {
			const userData = new User();
			userData.fetchUser().then((response) => {
				const { type } = response.response;
				return type === distributor ? next() : next('/store');
			}).catch(() => next('/store'));
		}
		next();
	},
	children: [
		{
			path: '/register-old/product',
			name: 'RegisterOldProduct',
			component: () => import('@/views/RegisterOld/Product'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/register-old/personal-information',
			name: 'RegisterOldPersonalInformation',
			component: () => import('@/views/RegisterOld/PersonalInformation'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/register-old/shipping',
			name: 'RegisterOldShipping',
			component: () => import('@/views/RegisterOld/Shipping'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/register-old/payment-method',
			name: 'RegisterOldPaymentMethod',
			component: () => import('@/views/RegisterOld/PaymentMethod'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/register-old/confirmation',
			name: 'RegisterOldConfirmation',
			component: () => import('@/views/RegisterOld/Confirmation'),
			meta: {
				auth: undefined,
			},
		},
		{
			path: '/register-old/success',
			name: 'RegisterOldSuccess',
			component: () => import('@/views/RegisterOld/Success'),
			meta: {
				auth: undefined,
			},
		},
	],
};
