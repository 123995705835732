<template>
	<div
		id="theme"
		:class="themeName">
		<router-view v-if="$user.ready()" />
		<button
			v-show="isProductionEnviroment && !liveChatLoaded && !isMobileAgencyProductDetails"
			id="liveChatButton"
			class="floating-button"
			:disabled="liveChatLoading"
			@click="includeLiveChat">
			<div class="center-div">
				<div style="width: 32px">
					<img
						v-if="!liveChatLoading"
						:src="require(`@/assets/images/common/live_chat.svg`)">
					<i
						v-else
						class="fa fa-fw fa-spinner fa-pulse" />
				</div>
			</div>
		</button>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';

/* eslint-disable no-unused-expressions */
import(`./assets/scss/themes/${process.env.VUE_APP_THEME}/index.scss`);

export default {
	name: 'App',
	mixins: [WindowSizes],
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
			liveChatLoading: false,
			liveChatLoaded: false,
		};
	},
	computed: {
		isProductionEnviroment() {
			return process.env.VUE_APP_ENV === 'production';
		},
		isMobileAgencyProductDetails() {
			try {
				const routeName = this.$route.name;

				return routeName.includes('Agency')
					&& routeName.includes('ProductDetails')
					&& ['xs', 'sm'].includes(this.windowWidth);
			} catch (e) {
				return false;
			}
		},
	},
	watch: {
		liveChatLoading(loaded) {
			if (loaded) {
				this.checkAPILoaded();
			}
		},
	},
	created() {
		this.includeSift();
	},
	methods: {
		includeSift() {
			/* eslint-disable */
			var _session_id = this.createUuid();
			window._sift_session_id = _session_id;
			if (this.siftEnabled()) {
		        var _sift = window._sift = window._sift || [];
		        _sift.push(['_setAccount', process.env.VUE_APP_LACORE_ACCOUNT]);
		        _sift.push(['_setUserId', '']);
		        _sift.push(['_setSessionId', _session_id]);
		        _sift.push(['_trackPageview']);
		        (function() {
		            function ls() {
		                var e = document.createElement('script');
		                e.src = 'https://cdn.sift.com/s.js';
		                document.body.appendChild(e);
		            }
		            if (window.attachEvent) {
		                window.attachEvent('onload', ls);
		            } else {
		                window.addEventListener('load', ls, false);
		            }
		        })();
			}
			/* eslint-enable */
		},
		includeLiveChat() {
			if (this.isProductionEnviroment) {
				this.liveChatLoading = true;
				// Live chat
				/* eslint-disable */
				window.__lc = window.__lc || {};
				window.__lc.license = process.env.VUE_APP_LIVECHAT_LICENSE;
				(function() {
				  var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
				  lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
				  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
				})();
				/* eslint-enable */
			}
		},
		// Checks when fake chat button is clicked, until the component is loaded
		checkAPILoaded() {
			if (window.LC_API && window.LC_API.is_loaded && window.LC_API.is_loaded()) {
				// Hide fake chat button and open chat window
				this.liveChatLoaded = true;
				window.LC_API.open_chat_window();
			} else {
				// Poll every 5 milliseconds until the API is loaded, to avoid button and chat window existing at same time
				setTimeout(this.checkAPILoaded, 5);
			}
		},
	},
};
</script>

<style scoped>
.floating-button {
	position: fixed;
	bottom: 16px;
	right: 78px;
	width: 60px;
	height: 60px;
	border-radius: 30px;
	background-color: #DF703D;
	color: white;
	text-align: center;
	cursor: pointer;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25), 0px 2px 5px rgba(0, 0, 0, 0.15);
	border: none;
	outline: none;
}

@media only screen and (max-width: 768px) {
	.floating-button {
		right: 16px;
	}
}

.center-div {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
</style>
