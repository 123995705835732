export default {};
export const VAR_NAME = 'replicated';
export const SPONSOR_VAR_NAME = 'replicatedSponsor';
export const SPONSOR_GROUP_VAR_NAME = 'replicatedSponsorGroup';
export const REPLICATED_HIDE_STORE_VAR_NAME = 'replicatedHideStore';
export const CHECK_FOR_PROMO_VAR_NAME = 'checkForPromo';
export const DISCOUNT_CODE = 'discount_code';
export const PAGE_VAR_NAME = 'replicatedPage';
export const SOCIAL_NETWORK = ['twitter', 'facebook', 'instagram', 'linkedin', 'tiktok'];
export const SPECIAL_ICONS = {
	tiktok: {
		type: 'svg',
		value: 'tiktok.svg',
	},
};
export const COMMUNICATION = ['email', 'mobile'];
export const AGENCY_REPLICATED_SITE_NAME = 'SalesAgency';
export const AGENCY_REPLICATED_SITE_ROUTES_PREFIX = 'Agency';
export const AGENCY_REPLICATED_SITE_PATHS = {
	meta: 'nootropics',
	google: 'direct',
};
export const AGENCY_DISCOUNT_OPTION_KEY = 'deals';
export const AGENCY_COUNTDOWN_MINUTES = 15;
export const SPECIAL_VALUES = {
	email: (value) => `mailto:${value}`,		// const mail = value.split('').map(item => `&#${item.charCodeAt()};`).join('');
	// return `mailto:${mail}`;

	mobile: (value) => `tel:${value}`,
	skype: (value) => `skype:${value}?call`,
};
export const USER = ['profile_photo', 'biography', 'name'];
export const LANDING_PAGES = {
	store: {
		translation: { key: 'store' },
		icon: 'fas fa-store',
	},
	products: {
		translation: { key: 'products' },
		icon: 'fas fa-shopping-cart',
	},
	signup: {
		translation: { key: 'register' },
		route: { name: 'Register' },
		icon: 'fas fa-user-plus',
	},
	'signup-left': {
		translation: { key: 'register_left' },
		route: { name: 'Register' },
		icon: 'fas fa-user-plus',
	},
	'signup-right': {
		translation: { key: 'register_right' },
		route: { name: 'Register' },
		icon: 'fas fa-user-plus',
	},
	bran: {
		translation: { key: 'your_thing_landing_page', params: { name: 'bran' } },
		route: { name: 'BranLandingPage' },
		image: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/products/Velovita/thumbnails/br1003.png?v=1',
	},
	zlem: {
		translation: { key: 'your_thing_landing_page', params: { name: 'zlem' } },
		route: { name: 'ZlemLandingPage' },
		image: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/products/Velovita/thumbnails/zlem.png?v=1',
	},
	uuth: {
		translation: { key: 'your_thing_landing_page', params: { name: 'uuth' } },
		route: { name: 'UuthLandingPage' },
		image: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/products/Velovita/thumbnails/uuth.png?v=1',
	},
	plos: {
		translation: { key: 'your_thing_landing_page', params: { name: 'plos' } },
		route: { name: 'PlosLandingPage' },
		image: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/products/Velovita/thumbnails/plos_vanilla.png?v=1',
	},
	byom: {
		translation: { key: 'your_thing_landing_page', params: { name: 'byom' } },
		route: { name: 'ByomLandingPage' },
		image: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/products/Velovita/thumbnails/byom_strawberry.png?v=1',
	},
	tuun: {
		translation: { key: 'your_thing_landing_page', params: { name: 'tuun' } },
		route: { name: 'TuunLandingPage' },
		image: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/products/Velovita/thumbnails/tuun.png?v=1',
	},
	lite: {
		translation: { key: 'your_thing_landing_page', params: { name: 'lite' } },
		route: { name: 'LiteLandingPage' },
		image: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/products/Velovita/thumbnails/lite.png?v=1',
	},
	'opportunity-1': {
		translation: { key: 'opportunity_thing', params: { name: 'its_about_freedom' } },
		route: { name: 'Opportunity1LandingPage' },
		icon: 'far fa-lightbulb',
	},
	'opportunity-2': {
		translation: { key: 'opportunity_thing', params: { name: 'an_epic_journey' } },
		route: { name: 'Opportunity2LandingPage' },
		icon: 'fas fa-seedling',
	},
	'healthy-weight-loss': {
		translation: { key: 'healthy_weight_loss', params: {} },
		route: { name: 'HealthyWeightLossLandingPage' },
		icon: 'fas fa-weight',
	},
};

export const REALTIME_ANALYTICS_REFRESH_INTERVAL = 180000; // 5 minutes
