const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/highlight-videos',
	name: 'MainHighlightVideos',
	redirect: '/highlight-videos',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/highlight-videos',
			name: 'HighlightVideos',
			component: () => import('@/views/HighlightVideos'),
			meta: {
				auth: true,
				section: 'highlight_videos',
				permissions: 'view',
			},
		},
		{
			path: '/highlight-videos/new',
			name: 'NewHighlightVideo',
			component: () => import('@/views/HighlightVideos/NewHighlightVideo'),
			meta: {
				auth: true,
				section: 'highlight_videos',
				permissions: 'create',
			},
		},
		{
			path: '/highlight-videos/:highlightVideoId/update',
			name: 'UpdateHighlightVideo',
			component: () => import('@/views/HighlightVideos/UpdateHighlightVideo'),
			meta: {
				auth: true,
				section: 'highlight_videos',
				permissions: 'update',
			},
		},
	],
};
