import Swal from 'sweetalert2';
import { TOAST } from '@/settings/Alerts';

class Alert {
	constructor() {
		this.config = {
			allowEscapeKey: false,
			allowOutsideClick: false,
			showCancelButton: true,
			showLoaderOnConfirm: true,
			icon: 'warning',
		};
	}

	error = (title, text, isHtml) => {
		const swalContent = { title };
		if (typeof isHtml === 'boolean' && isHtml === true) {
			swalContent.html = text;
		} else if (typeof isHtml !== 'boolean' || isHtml !== true) {
			swalContent.text = text;
		}
		return Swal.fire({ icon: 'error', ...swalContent });
	}

	success = (title, text, isHtml) => {
		const swalContent = { title };
		if (typeof isHtml === 'boolean' && isHtml === true) {
			swalContent.html = text;
		} else if (typeof isHtml !== 'boolean' || isHtml !== true) {
			swalContent.text = text;
		}
		return Swal.fire({ icon: 'success', ...swalContent });
	}

	preConfirm = (config, preConfirm, callback) => {
		if (typeof preConfirm !== 'function') {
			return Promise.reject(new Error('preconfirm_should_be_function'));
		}
		const { title, text } = config;
		if (typeof title === 'undefined') {
			return Promise.reject(new Error('title_required'));
		}

		if (typeof text === 'undefined') {
			return Promise.reject(new Error('text_required'));
		}

		const payload = { ...this.config, ...config, preConfirm };

		return Swal.fire(payload).then((response) => callback(response)).catch((error) => Promise.reject(error));
		// preConfirm: password => new Promise((resolve) => {
		// 		if (password.trim() === '') {
		// 			Swal.showValidationError(emptyPasswordMessage);
		// 		}
		// 		resolve();
		// 	}),
	}

	showValidationMessage = (message) => Swal.showValidationMessage(message);

	loading = (title, text, cnf) => {
		let config = cnf;
		if (typeof config === 'undefined') {
			config = {};
		}
		return Swal.fire({
			title,
			text,
			...config,
			onOpen: () => {
				Swal.showLoading();
			},
		});
	}

	confirmationWithPassword = (title, label, payload) => {
		let attributes = payload;
		if (typeof attributes === 'undefined') {
			attributes = {};
		}
		let {
			config, buttonText, emptyPasswordMessage, cancelButtonText,
		} = attributes;
		if (typeof config === 'undefined') {
			({ config } = this);
		}
		if (typeof buttonText === 'undefined') {
			buttonText = 'OK';
		}
		if (typeof cancelButtonText === 'undefined') {
			cancelButtonText = 'Cancel';
		}
		if (typeof emptyPasswordMessage === 'undefined') {
			emptyPasswordMessage = 'The Password field is required';
		}
		const body = {};
		body.html = (attributes.message) ? `<div class="text-center"><label>${attributes.message}</label></div>` : '';
		body.html += `<div class="text-left"><label class="font-weight-bold" style="float: left">${label} <span class="text-danger">*</span></label></div>`;
		return Swal.fire({
			title,
			input: 'password',
			confirmButtonText: buttonText,
			cancelButtonText,
			...body,
			...config,
			preConfirm: (password) => new Promise((resolve) => {
				if (password.trim() === '') {
					Swal.showValidationMessage(emptyPasswordMessage);
				}
				resolve();
			}),
		}).then((response) => {
			const { dismiss } = response;
			if (typeof dismiss === 'string' && dismiss === 'cancel') {
				return Promise.reject(new Error('cancel'));
			}
			return response;
		}).catch((error) => Promise.reject(error));
	}

	confirmationWithPasswordAndNotes = (title, passwordTitle, notesTitle, payload) => {
		let attributes = payload;
		if (typeof attributes === 'undefined') {
			attributes = {};
		}
		let { config, buttonText, emptyPasswordMessage } = attributes;
		if (typeof config === 'undefined') {
			({ config } = this);
		}
		if (typeof buttonText === 'undefined') {
			buttonText = 'OK';
		}
		if (typeof emptyPasswordMessage === 'undefined') {
			emptyPasswordMessage = 'The Password field is required';
		}

		const body = {};
		body.html = (attributes.message) ? `<div class="text-center"><label>${attributes.message}</label></div>` : '';
		body.html += `<div class="text-left"><label class="font-weight-bold" style="float: left">${notesTitle}</label></div>
					<textarea id="notes" class="swal2-textarea" style="display: flex"></textarea>
					<div class="text-left"><label class="font-weight-bold" style="float: left">${passwordTitle} <span class="text-danger">*</span></label></div>`;
		return Swal.fire({
			title,
			input: 'password',
			confirmButtonText: buttonText,
			...body,
			...config,
			onOpen: () => {
				document.getElementById('notes').focus();
			},
			preConfirm: (password) => new Promise((resolve) => {
				const notes = document.getElementById('notes').value;

				if (password.trim() === '') {
					Swal.showValidationMessage(emptyPasswordMessage);
				}
				resolve({
					notes,
					password,
				});
			}),
		}).then((response) => {
			const { dismiss } = response;
			if (typeof dismiss === 'string' && dismiss === 'cancel') {
				return Promise.reject(new Error('cancel'));
			}
			return response;
		}).catch((error) => Promise.reject(error));
	}

	confirmation = (title, message, options = {}, isHtml, preConfirm = () => {}) => {
		const body = {};
		let { confirmButtonText, cancelButtonText, config } = options;
		if (typeof config === 'undefined') {
			({ config } = this);
		}
		if (typeof confirmButtonText === 'undefined') {
			confirmButtonText = 'OK';
		}
		if (typeof cancelButtonText === 'undefined') {
			cancelButtonText = 'Cancel';
		}
		if (typeof isHtml === 'boolean' && isHtml === true) {
			body.html = message;
		} else if (typeof isHtml !== 'boolean' || isHtml !== true) {
			body.text = message;
		}
		return Swal.fire({
			title,
			confirmButtonText,
			cancelButtonText,
			...body,
			...config,
			preConfirm,
		}).then((response) => {
			const { dismiss } = response;
			if (typeof dismiss === 'string' && dismiss === 'cancel') {
				return Promise.reject(new Error('cancel'));
			}
			return response;
		}).catch((error) => Promise.reject(error));
	}

	toast = (icon, title, options) => {
		const config = { ...TOAST, ...options };
		const message = Swal.mixin({ toast: true, ...config });
		return message.fire({ icon, title });
	}

	toastImage = (icon, image, title, options) => {
		const config = { ...TOAST, ...options };
		const message = Swal.mixin({ toast: true, ...config });
		return message.fire({
			icon,
			padding: 10,
			imageUrl: image.url,
			imageWidth: image.imageWidth ? image.imageWidth : 'auto',
			imageHeight: image.imageHeight ? image.imageHeight : 'auto',
			html: `<h5 style="padding: 0 1em;">${title}</h5>`,
			customClass: {
				icon: 'mx-1',
				image: 'my-0 toast-img-mw',
			},
		});
	}

	close = () => Swal.close()
}
export default Alert;
