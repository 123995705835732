import { TREE_TYPES } from '@/settings/Tree';

const Header = () => import('@/views/Layouts/Main/Header');

export const Network = {
	path: '/my-network',
	name: 'MainNetwork',
	redirect: '/my-network/binary-tree/',
	component: Header,
	children: [
		{
			path: '/my-network/binary-tree/:userId?',
			name: 'BinaryTree',
			component: () => import('@/views/Network/BinaryTree'),
			meta: {
				auth: true,
				section: 'binary_tree',
				permissions: 'view',
			},
			props: () => ({ treeType: TREE_TYPES.BINARY }),
		},
		{
			path: '/my-network/unilevel-tree',
			name: 'UnilevelTree',
			component: () => import('@/views/Network/UnilevelTree'),
			meta: {
				auth: true,
				section: 'unilevel_tree',
				permissions: 'view',
			},
		},
		{
			path: '/my-network/unilevel-summary',
			name: 'UnilevelSummary',
			redirect: '/my-network/unilevel-summary/data',
			component: () => import('@/views/Network/UnilevelSummary'),
			meta: {
				auth: true,
				section: 'unilevel_summary',
				permissions: 'view',
			},
			children: [
				{
					path: '/my-network/unilevel-summary/data',
					name: 'UnilevelSummaryData',
					component: () => import('@/views/Network/UnilevelSummaryData'),
					meta: {
						auth: true,
						section: 'unilevel_summary',
						permissions: 'view',
					},
				},
				{
					path: '/my-network/unilevel-summary/details',
					name: 'UnilevelSummaryDetails',
					component: () => import('@/views/Network/UnilevelSummaryDetails'),
					meta: {
						auth: true,
						section: 'unilevel_summary',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/my-network/sponsored-distributors/:distributorId?',
			name: 'SponsoredDistributors',
			component: () => import('@/views/Network/SponsoredDistributors'),
			meta: {
				auth: true,
				section: 'sponsored_distributors',
				permissions: 'view',
			},
		},
		{
			path: '/my-network/sponsored-customers/:distributorId?',
			name: 'SponsoredCustomers',
			component: () => import('@/views/Network/SponsoredCustomers'),
			meta: {
				auth: true,
				section: 'sponsored_customers',
				permissions: 'view',
			},
		},
		{
			path: '/my-network/downlines-info/:distributorId?',
			name: 'DownlinesInfo',
			component: () => import('@/views/Network/DownlinesInfo'),
			meta: {
				auth: true,
				section: 'downlines_info',
				permissions: 'view',
			},
		},
		{
			path: '/my-network/distributor-organization',
			name: 'DistributorOrganization',
			component: () => import('@/views/Network/DistributorOrganization'),
			meta: {
				auth: true,
				section: 'distributor_organization',
				permissions: 'view',
			},
		},
	],
};

export const SponsoredCustomers = {
	path: '/sponsored-customers/:distributorId?',
	name: 'NetworkCustomers',
	component: Header,
	children: [
		{
			path: '/sponsored-customers/:distributorId?',
			name: 'NetworkSponsored',
			component: () => import('@/views/Network/SponsoredCustomers'),
			meta: {
				auth: true,
				role: ['customer', 'affiliate'],
			},
		},
	],
};

export default {};
