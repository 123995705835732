/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import store from '@/store/Login';

const Header = () => import('@/views/Layouts/Purchase');

export default {
	path: '/become-an-affiliate',
	name: 'BecomeAffiliateMain',
	redirect: () => (!store.state.isLogged ? { name: 'BecomeAffiliate' } : { name: 'Store' }),
	component: Header,
	children: [
		{
			path: '/become-an-affiliate',
			name: 'BecomeAffiliate',
			component: () => import('@/views/BecomeAffiliate'),
			meta: {
				auth: false,
			},
		},
	],
};
