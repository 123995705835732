const Header = () => import('@/views/PhysicalStore/Layouts/Store');
export default {
	path: '/pickup/cart',
	name: 'PSMainCart',
	redirect: '/pickup/cart',
	component: Header,
	children: [
		{
			path: '/pickup/cart',
			name: 'PSCart',
			component: () => import('@/views/PhysicalStore/Cart'),
			meta: {
				auth: undefined,
				section: 'pickup_store',
				permissions: 'view',
			},
		},
	],
};
