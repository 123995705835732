const Auth = {
	install(Vue) {
		Vue.mixin({
			beforeRouteEnter(to, from, next) {
				next((vm) => {
					const {
						auth, permissions, section, role,
					} = to.meta;

					if (typeof auth === 'undefined') { return next(); }

					if (vm.$user.auth()) {
						if (typeof permissions !== 'undefined') {
							if (typeof section === 'undefined') {
								console.warn(`The value of the module is not fount in ${to.path}. Please add it on section attribute inside of route.meta`);
							}
							if (vm.$can(section, permissions)) {
								if (role instanceof Array) {
									const { type } = vm.$user.details();
									if (role.includes(type)) {
										return next();
									}
									return next({ name: 'Forbidden' });
								}
								return next();
							}
							const { matched } = vm.$route;
							if (matched.length > 1) {
								let routeToCheck = JSON.parse(JSON.stringify(vm.$router.options.routes));
								matched.map((item, index) => {
									if (index < matched.length - 1) {
										routeToCheck = routeToCheck.filter((itemRoute) => item.name === itemRoute.name)[0].children;
									}
									return null;
								});
								try {
									[routeToCheck] = routeToCheck.filter((item) => {
										const response = (vm.$can(item.meta.section, item.meta.permissions)) ? item.name : null;
										return response;
									});
									if (typeof routeToCheck === 'object') {
										return next({ name: routeToCheck.name });
									}
								} catch (error) {
									return next({ name: 'Forbidden' });
								}
							}
							return next({ name: 'Forbidden' });
						}

						if (role instanceof Array) {
							const { type } = vm.$user.details();
							if (role.includes(type)) {
								return next();
							}
							return next({ name: 'Forbidden' });
						}

						if (auth === true) {
							return next();
						}

						if (auth === false) {
							return next({ name: 'Store' });
						}
					} else if (!vm.$user.auth()) {
						if (auth === true) {
							return next({ name: 'Store' });
						}

						if (auth === false) {
							return next();
						}
					}
					return next(false);
				});
			},
		});
	},
};

export default Auth;
