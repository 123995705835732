export default {};
const today = 'today';
const yesterday = 'yesterday';
const thisWeek = 'current_week';
const lastWeek = 'last_week';
const lastTwoWeeks = 'last_two_weeks';
const weeklyAllTime = 'weekly_all_time';
const thisMonth = 'current_month';
const lastMonth = 'last_month';
const lastThreeMonths = 'last_three_months';
const monthlyAllTime = 'monthly_all_time';
const currentYear = 'current_year';
const lastYear = 'last_year';
const yearlyAllTime = 'yearly_all_time';

// Hour filters
const lastHour = 'last_hour';
const lastThreeHours = 'last_three_hours';
const lastSixHours = 'last_six_hours';

export const allTime = 'all_time';
export const custom = 'custom';

export const DATE_RANGES = {
	today,
	yesterday,
	this_week: thisWeek,
	last_week: lastWeek,
	last_two_weeks: lastTwoWeeks,
	this_month: thisMonth,
	last_month: lastMonth,
	current_year: currentYear,
	last_year: lastYear,
	// custom,
};

export const DATE_TIME_RANGES = {
	today,
	last_hour: lastHour,
	last_three_hours: lastThreeHours,
	last_six_hours: lastSixHours,
	yesterday,
	this_week: thisWeek,
	last_week: lastWeek,
	last_two_weeks: lastTwoWeeks,
	this_month: thisMonth,
	last_month: lastMonth,
	current_year: currentYear,
	last_year: lastYear,
	// custom,
};

export const PERIOD_FILTERS = {
	today,
	yesterday,
	this_week: thisWeek,
	last_week: lastWeek,
	last_two_weeks: lastTwoWeeks,
	this_month: thisMonth,
	last_month: lastMonth,
	last_three_months: lastThreeMonths,
	current_year: currentYear,
	last_year: lastYear,
	all_time: allTime,
	custom,
};

export const SALES_PERIOD_FILTERS = {
	today,
	last_hour: lastHour,
	last_three_hours: lastThreeHours,
	last_six_hours: lastSixHours,
	yesterday,
	this_week: thisWeek,
	last_week: lastWeek,
	last_two_weeks: lastTwoWeeks,
	this_month: thisMonth,
	last_month: lastMonth,
	current_year: currentYear,
	last_year: lastYear,
	all_time: allTime,
	custom,
};

export const ALL_TIME_PERIOD_FILTERS = {
	weekly_all_time: weeklyAllTime,
	monthly_all_time: monthlyAllTime,
	yearly_all_time: yearlyAllTime,
};

export const MONTHLY_FILTERS = {
	last_month: lastMonth,
	last_three_months: lastThreeMonths,
	current_year: currentYear,
	last_year: lastYear,
	all_time: allTime,
	custom,
};

export const PURCHASE_DATE = {
	expired: {
		days: 0,
		class: 'danger',
	},
	valid: {
		days: 5,
		class: 'info',
	},
	default: {
		class: 'info',
	},
};

export const DATE_RANGE_FILTER = 'dateRange';
export const DATE_START_FILTER = 'startDate';
export const DATE_END_FILTER = 'endDate';
export const YEAR_FORMAT = 'YYYY';
export const YMD_FORMAT = 'YYYY-MM-DD';
export const MDY_FORMAT = 'MMMM Do YYYY';
export const MY_FORMAT = 'MMMM YYYY';
export const MD_FORMAT = 'MMMM Do';
export const MMMD_FORMAT = 'MMM Do';
export const YMDHMS_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const YY_FORMAT = 'YY';
export const YM_FORMAT = 'YYYY-MM';
export const MMMDY_FORMAT = 'MMM D, YYYY';
export const MMMDYHMS_FORMAT = 'MMM D, YYYY HH:mm';
export const MMMDYHMA_FORMAT = 'MMM D, YYYY hh:mm A';
export const MMDD_FORMAT = 'MM/DD';
export const MMMM_FORMAT = 'MMMM';
export const DDDDMMMDO_FORMAT = 'dddd - MMM Do';
export const DO_FORMAT = 'Do';

export const V_CALENDAR_CONFIG = {
	formats: {
		title: 'MMMM YYYY',
		weekdays: 'W',
		navMonths: 'MMM',
		input: [YMD_FORMAT, 'YYYY-MM-DD', 'YYYY/MM/DD'],
		dayPopover: 'L',
	},
};

export const COUNTDOWN_UNITS = {
	days: 86400000,
	hours: 3600000,
	minutes: 60000,
	seconds: 1000,
};

export const MEMBERSHIP_REMAINING_DAYS = 30;
export const RENEW_MEMBERSHIP_DAYS = 0;

export const JANUARY = { key: 'january', value: '01' };
export const FEBRUARY = { key: 'february', value: '02' };
export const MARCH = { key: 'march', value: '03' };
export const APRIl = { key: 'april', value: '04' };
export const MAY = { key: 'may', value: '05' };
export const JUNE = { key: 'june', value: '06' };
export const JULY = { key: 'july', value: '07' };
export const AUGUST = { key: 'august', value: '08' };
export const SEPTEMBER = { key: 'september', value: '09' };
export const OCTOBER = { key: 'october', value: '10' };
export const NOVEMBER = { key: 'november', value: '11' };
export const DECEMBER = { key: 'december', value: '12' };

export const MONTHS = [JANUARY, FEBRUARY, MARCH, APRIl, MAY, JUNE, JULY, AUGUST, SEPTEMBER, OCTOBER, NOVEMBER, DECEMBER];

export const FRAUD_START_YEAR = 2020;
export const TAXES_START_YEAR = 2020;
export const REFUNDS_START_YEAR = 2020;
export const SCHEDULE_START_YEAR = 2020;
export const LIFETIME_VALUE_START_YEAR = 2020;
