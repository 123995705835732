const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/push-notifications',
	name: 'MainPushNotifications',
	redirect: '/push-notifications',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/push-notifications',
			name: 'PushNotifications',
			component: () => import('@/views/PushNotifications'),
			meta: {
				auth: true,
				section: 'push_notifications',
				permissions: 'view',
			},
		},
		{
			path: '/push-notifications/send',
			name: 'NewPushNotification',
			component: () => import('@/views/PushNotifications/Send'),
			meta: {
				auth: true,
				section: 'push_notifications',
				permissions: 'add',
			},
		},
	],
};
