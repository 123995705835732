/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import store from '@/store/Login';

import { admin } from '@/settings/Roles';

const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/wallets',
	name: 'MainWallets',
	redirect: '/wallets',
	component: Header,
	children: [
		{
			path: '/wallets',
			name: 'Wallets',
			redirect: () => {
				// Access user permissions from Vuex store
				const { wallet_transactions: walletTransactions } = store.state.user.permissions || {};

				// Check if the user has 'view' permission for wallet transactions
				if (walletTransactions && walletTransactions.permissions.includes('view')) {
					return '/wallets/transactions/commissions';
				}

				return '/wallets/points';
			},
			component: () => import('@/views/Wallets'),
			meta: {
				auth: true,
				section: 'wallet_transactions',
				permissions: 'view',
			},
			children: [
				{
					path: '/wallets/transactions/commissions',
					name: 'WalletsTransactions',
					component: () => import('@/views/Wallets/Transactions'),
					meta: {
						auth: true,
						section: 'wallet_transactions',
						permissions: 'view',
					},
				},
				{
					path: '/wallets/withdrawal',
					name: 'WalletsWithdrawal',
					component: () => import('@/views/Wallets/Withdrawal'),
					meta: {
						auth: true,
						section: 'wallet_transactions',
						permissions: 'view',
					},
				},
				{
					path: '/wallets/points',
					name: 'WalletsPoints',
					component: () => import('@/views/Wallets/Points'),
					meta: {
						auth: true,
						section: 'points',
						permissions: 'view',
					},
				},
				{
					path: '/wallets/summaries',
					name: 'WalletsPointsSummaries',
					component: () => import('@/views/Wallets/PointsSummaries'),
					meta: {
						auth: true,
						section: 'points',
						permissions: 'view',
					},
				},
				{
					path: '/wallets/balances',
					name: 'WalletsBalances',
					component: () => import('@/views/Wallets/Balances'),
					meta: {
						auth: true,
						role: admin,
						section: 'wallet_transactions',
						permissions: 'view',
					},
				},
				{
					path: '/wallets/settings',
					name: 'WalletsSettings',
					component: () => import('@/views/Wallets/Settings'),
					meta: {
						auth: true,
						section: 'wallet_transactions',
						permissions: 'view',
					},
				},
				{
					path: '/wallets/transfer',
					name: 'WalletsTransfer',
					component: () => import('@/views/Wallets/Transfer'),
					meta: {
						auth: true,
						section: 'wallet_transactions',
						permissions: 'transfer',
					},
				},
			],
		},
	],
};
