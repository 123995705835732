const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/orders/event-tickets',
	name: 'MainEventTickets',
	redirect: '/orders/event-tickets/all',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/orders/event-tickets',
			name: 'EventTickets',
			component: () => import('@/views/Orders/EventTickets'),
			meta: {
				auth: true,
				section: 'event_tickets',
				permissions: 'view',
			},
			children: [
				{
					path: '/orders/event-tickets/all',
					name: 'EventTicketsAll',
					component: () => import('@/views/Orders/EventTicketsAll'),
					meta: {
						auth: true,
						section: 'event_tickets',
						permissions: 'view',
					},
				},
				{
					path: '/orders/event-tickets/confirmed/',
					name: 'EventTicketsCheckedIn',
					component: () => import('@/views/Orders/EventTicketsCheckedIn'),
					meta: {
						auth: true,
						section: 'event_tickets',
						permissions: 'confirm',
					},
				},
				{
					path: '/orders/event-tickets/confirm/',
					name: 'EventTicketsCheckIn',
					component: () => import('@/views/Orders/EventTicketsCheckIn'),
					meta: {
						auth: true,
						section: 'event_tickets',
						permissions: 'confirm',
					},
				},
				// {
				// 	path: '/orders/event-tickets/payments/',
				// 	name: 'EventTicketsPayments',
				// 	component: () => import('@/views/Orders/EventTicketsPayments'),
				// 	meta: {
				// 		auth: true,
				// 		section: 'event_tickets',
				// 		permissions: 'view',
				// 	},
				// },
				// {
				// 	path: '/orders/event-tickets/hotel-information/',
				// 	name: 'HotelInformation',
				// 	component: () => import('@/views/Orders/HotelInformation'),
				// 	meta: {
				// 		auth: true,
				// 		role: ['admin', 'corporate'],
				// 		section: 'event_tickets',
				// 		permissions: 'view',
				// 	},
				// },
			],
		},
		{
			path: '/orders/event-tickets/new-ticket',
			name: 'NewEventTicket',
			component: () => import('@/views/Orders/NewEventTicket'),
			meta: {
				auth: true,
				section: 'event_tickets',
				permissions: 'add',
			},
		},
		{
			path: '/orders/event-tickets/:ticketId/edit-ticket',
			name: 'EditEventTicket',
			component: () => import('@/views/Orders/EditEventTicket'),
			meta: {
				auth: true,
				section: 'event_tickets',
				role: ['admin', 'corporate'],
			},
		},
		{
			path: '/orders/event-tickets/:ticketId/user-edit',
			name: 'UserEditEventTicket',
			component: () => import('@/views/Orders/UserEditEventTicket'),
			meta: {
				auth: true,
				role: ['distributor'],
			},
		},
	],
};
