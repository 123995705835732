/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import { PURCHASE_LOGGED_STEPS } from '@/settings/Wizard';
import store from '@/store/Login';

const Header = () => import('@/views/Layouts/Purchase');
export default {
	path: '/purchase',
	name: 'Purchase',
	redirect: () => (store.state.isLogged ? { name: Object.keys(PURCHASE_LOGGED_STEPS)[0] } : { name: 'Store' }),
	component: Header,
	children: [
		{
			path: '/purchase/pack',
			name: 'PurchasePack',
			component: () => import('@/views/Register/Pack'),
			meta: {
				auth: true,
			},
		},
		{
			path: '/purchase/confirmation',
			name: 'PurchaseConfirmation',
			component: () => import('@/views/Purchase/Confirmation'),
			meta: {
				auth: true,
			},
		},
		{
			path: '/purchase/success',
			name: 'PurchaseSuccess',
			component: () => import('@/views/Purchase/Success'),
			meta: {
				auth: true,
			},
			props: (route) => ({
				paymentMethodName: route.query.payment_method ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
			}),
		},
		{
			path: '/gift-orders/success',
			name: 'GiftOrderSuccess',
			component: () => import('@/views/GiftOrders/components/Success'),
			meta: {
				auth: true,
			},
			props: (route) => ({
				paymentMethodName: route.query.payment_method ?? '',
				verificationRequired: !!route.query.verification_required ?? false,
			}),
		},
	],
};
