const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/support',
	name: 'MainSupport',
	redirect: '/support',
	component: Header,
	children: [
		{
			path: '/support',
			name: 'Support',
			component: () => import('@/views/Support/Send'),
			meta: {
				auth: true,
				role: ['customer', 'distributor', 'pre_distributor', 'affiliate', 'agency'],
			},
		},
	],
};
