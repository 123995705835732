import {
	AUTH, UNPROCESSABLE, COMMON_ERRORS,
} from '@/settings/Errors';

class Errors {
	constructor() {
		this.errors = {};
		this.messageError = null;
		this.status = 200;
	}

	all() {
		return this.errors;
	}

	message() {
		if (this.messageError) { return this.messageError; }
		return null;
	}

	has(field) {
		if (typeof this.errors[field] !== 'undefined') { return true; }
		return false;
	}

	any() {
		return Object.keys(this.errors).length > 0;
	}

	get(field) {
		if (this.errors[field]) {
			return this.errors[field];
		}
		return null;
	}

	set(field, error) {
		if (typeof field === 'object') {
			this.errors = field;
		} else if (typeof field === 'string') {
			this.errors[field] = error;
		}
	}

	record(errors) {
		if (typeof errors.response === 'undefined') {
			this.status = errors.status;
			if (UNPROCESSABLE.includes(errors.status) || AUTH.includes(errors.status)) {
				this.messageError = errors.data.errors.message;
				this.errors = errors.data.errors.fields;
			} else if (COMMON_ERRORS.includes(errors.status)) {
				this.messageError = errors.data.errors.message;
				this.errors = {};
			}
		} else if (typeof errors.response !== 'undefined') {
			this.status = errors.response.status;
			if (UNPROCESSABLE.includes(errors.response.status)) {
				try {
					this.messageError = errors.data.errors.message;
					this.errors = errors.data.errors.fields;
				} catch (error) {
					this.messageError = errors.response.data.errors.message;
					this.errors = errors.response.data.errors.fields;
				}
			} else if (COMMON_ERRORS.includes(errors.response.status)) {
				this.messageError = errors.response.data.message;
				this.errors = {};
			}
		}
	}

	clear(field) {
		if (field === true) {
			this.errors = {};
		}
		if (field) {
			delete this.errors[field];
			this.errors = { ...this.errors };
		}
	}
}

export default Errors;
