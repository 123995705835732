export const RESPONSE_TREE = 'data';
export const ASC_ORDER = 'ASC';
export const DESC_ORDER = 'DESC';

export const ORDER_FIELD_NAME = 'sortCol';
export const ORDER_DIR_NAME = 'sortDir';
export const PAGINATION = 'page';

export const QUERY_VARS = [ORDER_FIELD_NAME, ORDER_DIR_NAME, PAGINATION];
export default {};
