export const admin = ['admin', 'corporate'];
export const superAdmin = 'admin';
export const distributor = 'distributor';
export const customer = 'customer';
export const affiliate = 'affiliate';
export const corporate = 'corporate';
export const agency = 'agency';
export const preDistributor = 'pre_distributor';
export const customerRoles = ['affiliate', 'customer'];
export const agencies = ['agency'];
export const allRoles = ['admin', 'distributor', 'customer', 'affiliate', 'corporate', 'pre_distributor', 'agency'];
export const CREATE_DISTRIBUTOR_TYPES = ['distributor', 'agency'];
export default {};
