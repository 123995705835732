import Errors from './Errors';

class Form {
	constructor(data) {
		this.originalData = data;
		this.isClear = true;

		Object.keys(data).forEach((field) => {
			this[field] = data[field];
		});

		this.errors = new Errors();
	}

	data() {
		const data = {};

		Object.keys(this.originalData).forEach((property) => {
			data[property] = this[property];
		});

		return data;
	}

	reset() {
		Object.keys(this.originalData).forEach((field) => {
			this[field] = '';
		});

		this.errors.clear();
		this.isClear = true;
	}

	isClear() {
		return this.isClear;
	}
}

export default Form;
