export const ALL_KEY = 'ALL';

// The diacritic characters specified in the arrays will be replaced with the corresponding key
export const REPLACEMENTS = {
	a: ['à', 'á', 'â', 'ä', 'æ', 'ã', 'å', 'ā'],
	e: ['è', 'é', 'ê', 'ë', 'ē', 'ė', 'ę'],
	i: ['î', 'ï', 'í', 'ī', 'į', 'ì'],
	o: ['ô', 'ö', 'ò', 'ó', 'œ', 'ø', 'ō', 'õ'],
	u: ['û', 'ü', 'ù', 'ú', 'ū'],
};

export default {};
