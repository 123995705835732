import {
	GET_COUNTRIES,
	FETCH_COUNTRY_BY_IP,
	GET_STATES,
	GET_STATE,
	GET_SUBURBS,
	GET_COUNTRY,
	GET_CITIES,
	GET_DISTRICTS, GET_REPORT_COUNTRIES, GET_BILLING_COUNTRIES,
} from '@/config/endpoint';
import { DEFAULT_COUNTRY } from '@/settings/Country';
import { thirdParty } from '@/config/axios';
import Req from './AxiosRequest';

class Country {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getCountries(options = {}) {
		const { method, endpoint } = GET_COUNTRIES;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBillingCountries(options = {}) {
		const { method, endpoint } = GET_BILLING_COUNTRIES;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getReportCountries(options = {}) {
		const { method, endpoint } = GET_REPORT_COUNTRIES;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getStates(countryCode) {
		const { method, endpoint } = GET_STATES;
		return this.data[method](endpoint(countryCode)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSuburbs(postalCode) {
		const { method, endpoint } = GET_SUBURBS;
		return this.data[method](endpoint(postalCode)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCities(regionId) {
		const { method, endpoint } = GET_CITIES;
		return this.data[method](endpoint(regionId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDistricts(cityId) {
		const { method, endpoint } = GET_DISTRICTS;
		return this.data[method](endpoint(cityId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	setCountryByIP() {
		const { method, endpoint } = FETCH_COUNTRY_BY_IP;
		return thirdParty[method](endpoint).then((response) => {
			try {
				const { countryCode } = response.data;
				return countryCode;
			} catch (error) {
				return DEFAULT_COUNTRY;
			}
		}).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getState(regionId) {
		const { method, endpoint } = GET_STATE;
		return this.data[method](endpoint(regionId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCountry(countryCode) {
		const { method, endpoint } = GET_COUNTRY;
		return this.data[method](endpoint(countryCode)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Country;
