import { TRANSFER_FEE_STEPS } from '@/settings/Wizard';

const MainHeader = () => import('@/views/Layouts/Main/Header');
const MembershipRenewalContainer = () => import('@/views/Layouts/Main/MembershipRenewalContainer');
const Header = () => import('@/views/Layouts/Purchase');

export const AccountTransferFeeSteps = {
	path: '/account-transfer-fee',
	name: 'TransferFee',
	redirect: { name: Object.keys(TRANSFER_FEE_STEPS)[0] },
	component: Header,
	children: [
		{
			path: '/transfer-fee/product',
			name: 'TransferFeeProduct',
			component: () => import('@/views/AccountTransfer/Product'),
			meta: {
				auth: true,
				role: ['distributor'],
			},
		},
		{
			path: '/transfer-fee/confirmation',
			name: 'TransferFeeConfirmation',
			component: () => import('@/views/AccountTransfer/PackageConfirmation'),
			meta: {
				auth: true,
				role: ['distributor'],
			},
		},
		{
			path: '/transfer-fee/success',
			name: 'TransferFeeSuccess',
			component: () => import('@/views/AccountTransfer/Success'),
			meta: {
				auth: true,
				role: ['distributor'],
			},
		},
	],
};

export const AccountFeeMain = {
	path: '/account-transfer-fee',
	redirect: '/account-transfer-fee',
	name: 'AccountTransferFeeContainer',
	component: MembershipRenewalContainer,
	children: [
		{
			path: '/account-transfer-fee',
			name: 'AccountTransferFeeMain',
			redirect: '/account-transfer-fee',
			component: MainHeader,
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/account-transfer-fee',
					name: 'AccountTransferFee',
					component: () => import('@/views/AccountTransferFee'),
					meta: {
						auth: true,
						role: ['distributor'],
					},
				},
			],
		},
	],
};

export default {};
