import { EVENTS, TRACKED_COUNTRIES, TRACKED_ROUTES } from '@/settings/GoogleTagManager';
import Country from '@/util/Country';

// Since vue-gtm has an ignored views list, we block all routes by default and
// only allow the ones that we want to track.
export const getAllRouteNames = (routes) => routes.reduce((result, route) => {
	if (route.name) {
		result.push(route.name);
	}
	if (route.children) {
		result.push(...getAllRouteNames(route.children));
	}
	return result;
}, []);

// Returns an array of all route names that should be blocked by vue-gtm
export const getIgnoredRoutes = (routes) => {
	const trackedRoutes = TRACKED_ROUTES;

	// If empty, we can track all routes
	if (trackedRoutes.length === 0) {
		return [];
	}

	const allRoutes = getAllRouteNames(routes);
	return allRoutes.filter((route) => !trackedRoutes.includes(route));
};

// Returns the country code based on the current user's IP address
export const getCountryByIP = () => {
	const countries = new Country();
	return countries.setCountryByIP().then((response) => response).catch((error) => Promise.reject(error));
};

// Returns true if the user's IP address is in a tracked country
export const canTrack = () => {
	// If TRACKED_COUNTRIES is empty, we can track all countries
	if (TRACKED_COUNTRIES.length === 0) {
		return Promise.resolve(true);
	}
	// If we're on a tracked country, we can track it
	const country = getCountryByIP();
	return country.then((response) => {
		if (TRACKED_COUNTRIES.includes(response)) {
			return true;
		}
		return false;
	}).catch(() => false); // If we can't get the country, we can't track it
};

export const trackEvent = (gtm, eventCodeName, value = 0, labelValue = '') => {
	const event = EVENTS[eventCodeName];
	if (event) {
		const label = (typeof (event.label) === 'function') ? event.label(labelValue) : labelValue || event.label;

		gtm.trackEvent({
			event: event.event,
			category: event.category,
			action: event.action,
			label,
			value,
		});
	} else {
		console.error(`Event of type ${eventCodeName} not found in settings`);
	}
};

export default {};
